$color_1: #2a578d;
$color_2: #545454;
button:focus {
  // outline: solid #9bc9ff !important;
}
.index-btn {
  box-shadow: 0 0 4px 2px rgb(231 231 231) !important;
}
.post-counselling {
  display: block;
  border: 1px solid $color_1;
  border-radius: 8px !important;
  margin-bottom: 15px;
  box-shadow: 0px 0px 4px 2px #0000002e;
  padding: 10px;
  h5 {
    text-align: left;
    font-size: 16px !important;
    color: #3d405b;
    font-weight: 600;
    margin-bottom: 0.3rem !important;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif !important;
    min-height: 45px;
  }
  p {
    margin: 0;
    text-align: left;
    font-weight: 400;
    color: #3d405b;
    font-size: 16px;
    font-family: Montserrat, sans-serif !important;
  }
  svg {
    color: $color_1;
    height: 20px;
    border: 1px solid;
    border-radius: 50%;
    padding: 2px;
    width: 20px;
  }
  .sec-pos {
    position: relative;

    svg {
      color: $color_1;
      height: 20px;
      border: 1px solid;
      border-radius: 50%;
      padding: 2px;
      position: absolute;
      top: 10px;
      width: 20px;
    }

    .waiting-text {
      text-align: right;
      color: $color_1;
      position: absolute;
      right: 0;
      bottom: 2px;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
@media (min-width: 768px) and (max-width: 2200px) {
  // .post-counselling {
  //   margin: 1% 15% 2% 15%;
  // }
  .top-title h2 {
    font-size: 32px;
  }

  .top-title .heading {
    padding-top: 0%;
    padding-left: 0%;
  }
}
.container.my-proposals {
  text-align: -webkit-center;
}
.post-consel-ditel h5 {
  position: relative;
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 1rem;
  overflow: hidden;
  width: 255px !important;
  max-height: 34px;
}
.post-consel-ditel .price {
  font-size: 18px;
  font-weight: 500;
}
.post-consel-ditel span {
  text-align: left;
  display: block;
  color: #3d405b;
  font-size: 16px;
}

.pad.i {
  background-image: linear-gradient(to top, #fff 0%, #fff 50%, #90dffe 250%);
}
.post-counselling {
  display: block;
  border: 2px solid #d1d8df;
  border-radius: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 4px 1px #dee2e6;
}
.post-consel-ditel h5:after {
  content: "";
  width: 73%;
  height: 1px;
  background: #c7c7c7;
  position: absolute;
  left: 0px;
  right: 100%;
  margin: auto;
  bottom: -5px;
}

.session-counseling-div button:focus {
  // outline: solid 2px #c2e3f1 !important;
  outline: unset;
}
.session-counseling-div .index-btn {
  box-shadow: 0 0 4px 2px rgb(223 228 233) !important;
}
@media (min-width: 768px) and (max-width: 991px) {
  .session-counseling-div .btn-div {
    text-align: center;
  }
  .post-consel-ditel h5:after {
    width: 65%;
  }
}
@media (max-width: 767px) {
  .post-counselling h5 {
    font-size: 20px;
  }
  .session-counseling-div .btn-div {
    text-align: center;
  }
  .post-consel-ditel h5:after {
    width: 55%;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.session-counseling-div .index-btn.active,
.a2zpagination .index-btn.active {
  background: #2a578d;
  color: #fff;
  border-color: #2a578d;
}
