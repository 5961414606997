$color_1: #ffffff;
$color_2: #000;
$color_3: #2a578d;

.acor-abut {
  margin: 5px 0;
  border-radius: 0;
  background-color: $color_1;
  color: $color_1;
  box-shadow: 0 0.3rem 1.2rem rgb(0 0 0 / 15%) !important;
  border: 1px solid $color_1;

  .acor-summ {
    min-height: 38px !important;
  }

  .acor-ico {
    color: $color_3;
  }

  .acor-titl {
    color: $color_3;
    font-weight: 600;
    display: contents;
  }

  .MuiAccordionSummary-content {
    margin: 5px 0 !important;
  }

  .MuiAccordionDetails-root {
    padding: 0px 16px 10px;
  }
}
.det-button {
  text-align: center !important;

  .btn-8 {
    background-color: #f0ecfc;
    color: #ffffff;
    background-image: linear-gradient(315deg, #65768b 0%, #3d405b 74%);
    line-height: 42px;
    border: none;
    border-radius: 5px;
    padding: 0px 25px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
      7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
    outline: none;
  }
}

.det-button .btn-8 {
  background-color: #f0ecfc;
  background-image: linear-gradient(315deg, #407dc5 0%, #2a578d 74%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.det-button .btn-8 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.det-button .btn-8:before,
.det-button .btn-8:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: #4b0e78;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}
.det-button .btn-8:before {
  height: 0%;
  width: 2px;
}
.det-button .btn-8:after {
  width: 0%;
  height: 2px;
}
.det-button .btn-8:hover:before {
  height: 100%;
}
.det-button .btn-8:hover:after {
  width: 100%;
}
.det-button .btn-8:hover {
  background: transparent;
  color: #3d405b;
}
.det-button .btn-8 span:hover {
  color: #c797eb;
}
.det-button .btn-8 span:before,
.det-button .btn-8 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #c797eb;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}
.det-button .btn-8 span:before {
  width: 2px;
  height: 0%;
}
.det-button .btn-8 span:after {
  height: 2px;
  width: 0%;
}
.det-button .btn-8 span:hover:before {
  height: 100%;
}
.det-button .btn-8 span:hover:after {
  width: 100%;
}
.det-button button {
  margin: 20px;
}
.det-button .custom-btn {
  color: #ffffff;
  border-radius: 5px;
  padding: 0px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}
