.dropdown button {
  margin: 0.5rem;
}
.dropdown button:first-child {
  margin-left: 0rem;
}
.drop-costmize {
  padding: 4px 8px;
  width: 250px;
  font-size: 18px;
  font-weight: 300;

  label {
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding-bottom: 8px;
    input {
      width: 16px;
      height: 16px;
    }

    span {
      padding-left: 0.5rem;
    }
  }
}

.drop-btn-costmize {
  background-color: #2b2d42 !important;
  color: #fff !important;
  border-color: #2b2d42 !important;
}
button.drop-btn-costmize:focus {
  outline: none !important;
  box-shadow: none !important;
}
.drop-scroll-costmaize {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate3d(0px, 48.1818px, 0px);
  max-height: 150px;
  overflow: scroll;
  overflow-x: hidden;
}
.drop-scroll-costmaize.dropdown-menu.show {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #2a578d;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #2a578d;
  }
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em !important;
  vertical-align: middle !important;
  content: "";
  border-top: 0.4em solid !important;
  border-right: 0.4em solid transparent !important;
  border-bottom: 0;
  border-left: 0.4em solid transparent !important;
}
.select-categry-sarch-bar {
  input {
    border-radius: 6px;
    border: 1px solid lightgrey;
    margin-left: 8px;
    outline: unset;
  }
}

.category-item label {
  padding: 6px;
  background-color: white;
  margin: 4px 4px;
  border-radius: 4px;
  color: #3d405b;
  font-size: 16px;
  font-family: Montserrat, sans-serif !important;
}
input {
  border-radius: 6px;
  border: 1px solid lightgrey;
  margin-left: 8px;
  outline: unset;
}
