$color_1: #fff;
$color_2: #ffffff87;
$background-color_1: #2a578d;

.wallet-card {
  border-radius: 10px;
  padding: 15px;
  background-color: $background-color_1;
  h5 {
    color: $color_1;
  }
  p {
    font-size: 12px;
    color: $color_2;
  }
  h3 {
    margin-bottom: 0px;
    color: $color_1;
  }
  h6 {
    color: $color_1;
  }
}
.wallet-cart-img {
  img {
    width: 80px;
    height: 80px;
    padding: 5px;
    border-radius: 100%;
  }
}
.wallet-header-detail {
  h6 {
    color: $color_1;
    font-size: 18px;
    margin: 0px;
    margin-bottom: 5px;
  }
}
.wallet-amount-btn {
  width: 100%;
  font-weight: 500;
  border: 1px solid #fff;
  border-radius: 5px;
  color: $color_1;
  padding: 5px;
  background: #ffffff26;
}
.wallet-amount {
  text-align: right;
}
.table {
  td {
    color: $color_1;
  }
}
@media (min-width: 975px) {
  .center-algne {
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
  .wallet-card {
    width: 67%;
  }
}

@media (max-width: 975px) {
  .container.center-algne {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 750px) {
  .profile-cart {
    margin-bottom: 30px;
  }
}

label.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 7px;
  font-weight: 600;
}

.payment-heading-subtitle {
  color: #2a578d;
  font-weight: 500;
}

input[type="text"],
input[type="number"] {
  margin-top: 5px;
}
