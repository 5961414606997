.psess {
  background-color: #2a578d;
}

.sess {
  background-color: linear-gradient(to right, #c7b3b3, #2a578d) !important;
}
// .pskillssess {
//   background-color: linear-gradient(0deg, #2a578d, #ffff);
// }

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  color: black;
}
