//Arpit CSS
.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}

// body {
//   background-color: #485490;
// }

.textdesc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
a {
  color: #292b44;
  :hover {
    color: #fff;
  }
}
.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
  cursor: pointer;
}
.ag-courses-item_link {
  display: block;
  padding: 11px 12px;
  background-color: #5b84df;
  overflow: hidden;
  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 15px;
  margin: 0 0 1px;

  overflow: hidden;

  font-weight: bold;
  font-size: 16px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-heading {
  font-size: 35px;
  align-content: center;
  font-weight: 600;
  text-align-last: center;
  text-decoration: underline;
}
.ag-courses-item_date-box {
  font-size: 0.8rem;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #b39766;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 16px;
  }
  .ag-courses-heading {
    font-size: 38px;
    align-content: center;
    font-weight: 600;
    text-align-last: center;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}
@media only screen and (max-width: 639px) {
  .time-left {
    min-height: unset !important;
  }
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 16px;
  }
  .ag-courses-heading {
    font-size: 28px;
    align-content: center;
    font-weight: 600;
    text-align-last: center;
    text-decoration: underline;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}
// body {
//   background-color: black;
// }

// .container {
//   width: 1200px !important;
//   padding: 0 !important;
//   margin-right: auto;
//   margin-left: auto;

//   @media screen and (min-width: 992px) and (max-width: 1439px) {
//     max-width: 1279px !important;
//     padding: 0 !important;
//     margin: 0 80px !important;
//     width: auto !important;
//   }

//   @media screen and (max-width: 991px) {
//     max-width: 959px !important;
//     margin: 0 16px !important;
//     padding: 0 !important;
//     width: auto !important;
//   }
// }

// .gradient-cards {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 32px;
//   padding: 30px;
//   @media screen and (max-width: 991px) {
//     grid-template-columns: 1fr;
//   }
// }

// .container-title {
//   text-align: center;
//   padding: 0 !important;
//   margin-bottom: 40px;
//   font-size: 40px;
//   color: #fff;
//   font-weight: 600;
//   line-height: 60px;
// }

// .card {
//   max-width: 550px;
//   border: 0;
//   width: 100%;
//   margin-inline: auto;
// }

// .container-card {
//   position: relative;
//   border: 2px solid transparent;
//   background: linear-gradient(
//     71deg,
//     #5b84d8,
//     #3e96e3,
//     #1aa7ea,
//     #00b7ec,
//     #12c6eb
//   );
//   background-clip: padding-box;
//   border-radius: 45px;
//   padding: 40px;
//   img {
//     margin-bottom: 32px;
//   }
// }

// .bg-green-box,
// .bg-white-box,
// .bg-yellow-box,
// .bg-blue-box {
//   position: relative;
// }

// .bg-green-box::after,
// .bg-white-box::after,
// .bg-yellow-box::after,
// .bg-blue-box::after {
//   position: absolute;
//   top: -1px;
//   bottom: -1px;
//   left: -1px;
//   right: -1px;
//   content: "";
//   z-index: -1;
//   border-radius: 45px;
// }

// .bg-green-box::after {
//   background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
// }

// .bg-white-box::after {
//   background: linear-gradient(71deg, #121013, #b0afb0, #121013);
// }

// .bg-yellow-box::after {
//   background: linear-gradient(71deg, #110e0e, #afa220, #110e0e);
// }

// .bg-blue-box::after {
//   background: linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e);
// }

// .card-title {
//   font-weight: 600;
//   color: white;
//   letter-spacing: -0.02em;
//   line-height: 40px;
//   font-style: normal;
//   font-size: 28px;
//   padding-bottom: 8px;
// }

// .card-description {
//   font-weight: 600;
//   line-height: 32px;
//   color: hsla(0, 0%, 100%, 0.5);
//   font-size: 16px;
//   max-width: 470px;
// }
/* 
/* 



@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap");

/* 
========================
Variables
========================
*/

.my-custom-styles {
  font-size: 15px;
  --var-soft-blue: hsl(215, 51%, 70%);
  --var-cyan: hsl(178, 100%, 50%);
  --var-main-darkest: hsl(217, 54%, 11%);
  --var-card-dark: hsl(215, 41%, 51%);
  --var-line-dark: hsl(215, 32%, 27%);
  --var-lightest: white;
  --var-heading: normal normal 600 1em sans-serif;

  --var-para: normal normal 300 1em sans-serif;
}

.my-custom-styles.img {
  width: 100%;
  border-radius: 15px;
  display: block;
}

/* 

Eye view

https://i.postimg.cc/9MtT4GZY/view.png' border='0' alt='view */

/*
=========================
Font Styling
=========================
*/

.my-custom-styles.h1 {
  font: var(--var-heading);
  color: var(--var-lightest);
  padding: 1.2em 0;
}

.my-custom-styles.h2 {
  font: var(--var-small-heading);
  color: var(--var-lightest);
  /* padding on .coin-base */
}

.my-custom-styles.p {
  font: var(--var-para);
  color: var(--var-soft-blue);
}

.my-custom-styles.span {
  color: white;
}
/* 
=====================
Classes
=====================
*/

/* LAYOUT */
.session-card-div {
  border-radius: 1rem;
  background-color: #fff;
  img {
    border-radius: 1rem 1rem 0rem 0rem;
  }
  p {
    margin: 0;
  }
  .main-content {
    margin-bottom: 1rem;
    padding: 12px;
    label {
      font-weight: 500;
      font-size: 1rem;
      color: #2a578d;
      padding-right: 6px;
    }
    .skills {
      margin-top: 0px !important;
      .time-left {
        padding: 6px 0px;
        min-height: 90px;
        align-items: flex-start;
      }
    }
    .coin-base {
      padding: 12px 0px;
    }
    .tittel {
      padding: 4px 12px;
      width: fit-content;
      border-radius: 50px;
      color: #ffffff;
      background-image: linear-gradient(315deg, #8d8d8d, #437fc7 74%);
      margin-bottom: 12px;
    }
    p {
      color: #2a578d;
    }
    span {
      color: #2a578d;
    }
  }
  button.booking {
    border: 0;
    padding: 4px 8px;
    font-size: 1rem;
    color: white;
    font-weight: 500;
    background-color: #f0ecfc;
    background-image: linear-gradient(315deg, #65768b, #3d405b 74%);
    &:hover {
      background-color: #f0ecfc;
      background-image: linear-gradient(315deg, #65768b, #c4c5cf 74%);
    }
  }
}

.card-container {
  width: 100%;
  max-width: 400px;
  margin: 2em auto;
  background-color: var(--var-card-dark);
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1.2rem;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .card-container {
    width: 13 0%;
  }
}

div.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div.coin-base,
.time-left,
.card-attribute {
  display: flex;
  align-items: center;
  padding: 1em 0;
}

.card-attribute {
  padding-bottom: 1.5em;
  border-top: 2px solid var(--var-line-dark);
}

a.hero-image-container {
  position: relative;
  display: block;
}

/* Details */

img.eye {
  position: absolute;
  width: 100%;
  max-width: 2em;
  top: 44%;
  left: 43%;
}

@media (min-width: 400px) {
  img.eye {
    max-width: 3em;
  }
}

.hero-image-container::after {
  content: "";
  background-image: url("https://i.postimg.cc/9MtT4GZY/view.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5rem;
  background-color: hsla(178, 100%, 50%, 0.3);
  width: 100%;
  height: 100%;
  border-radius: 1rem 1rem 0rem 0rem;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.hero-image-container:hover::after {
  opacity: 1;
}

.small-image {
  width: 1.2em;
  margin-right: 0.5em;
}

.small-avatar {
  width: 2em;
  border-radius: 200px;
  outline: 2px solid white;
  margin-right: 1.4em;
}

div.attribution {
  margin: 0 auto;
  width: 100%;
  font: var(--var-para);
  text-align: center;
  padding: 1.5em 0 4em 0;
  color: var(--var-line-dark);
}
.attribution a {
  color: whitesmoke;
}

@media (min-width: 600px) {
  body {
    font-size: 18px;
  }
}
.booking {
  left: 50%;
  color: #080c51;
  border: #485490 300 2px;
  border-radius: 4px;
}
button.clear-all-btn {
  border: unset;
  background: linear-gradient(315deg, #8d8d8d, #437fc7 74%);
  color: #fff;
  border-radius: 5px;
  padding: 4px 10px;
  margin: 5px 0;
}
