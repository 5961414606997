@import url('https://fonts.googleapis.com/css?family=Merriweather+Sans');
.error-page{
	// height: 100vh;
	width: 100%;
    text-align: center;
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: 'Merriweather Sans', sans-serif;
	#errorText{
		font-size: 14px;
		margin: 22px 0;
    }
	#errorLink{
		font-size: 16px;
		padding: 5px 10px;
		border: 1px solid #2A578D; 
		color: #2A578D;
		background-color: transparent;
		text-decoration: none;
		transition: all 0.5s ease-in-out;
        border-radius: 5px;
		&:hover, &:active
        {
            color: #fff;
            background: #2A578D}
        }
	#g6219{
		transform-origin:85px 4px ;
		animation: an1 12s .5s infinite ease-out;
    }
  
@keyframes an1
{
	0%
    {
		transform: rotate(0);}
	5%{
		transform: rotate(3deg);}
	15%{
		transform: rotate(-2.5deg);}
	25%
    { transform: rotate(2deg);}
	35%
    {transform: rotate(-1.5deg);}
	45%
    {transform: rotate(1deg);}
	55%
    {transform: rotate(-1.5deg);}
	65%
    {transform: rotate(2deg);}
	75%
    {transform: rotate(-2deg);}
	85%
    {transform: rotate(2.5deg);}
	95%
    {transform: rotate(-3deg);}
	100%
    {transform: rotate(0);}
    }
}