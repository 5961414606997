$color_1: #ffffff;
$color_2: #3a4f68;
.footer {
  background-color: #3a4f68;
  padding: 50px 30px 25px 30px;
  color: $color_1;
  border-top: 2px solid;
  // margin-top: 28px;

  .top-footer {
    padding: 0 0 20px;
    border-bottom: 1px solid $color_1;
  }
  .footer-logo a {
    font-size: 1.5rem;
    color: $color_1;
    opacity: 0.75;
  }
  .tagline {
    font-size: 0.875rem;
    margin: 0;
    padding: 5px 0 15px;
    color: $color_1;
    opacity: 0.5;
  }
  h4 {
    font-size: 1.125rem;
    font-weight: bold;
    color: #d6d9db;
    line-height: 22px;
    margin: 14px 0 0;
    padding: 0 0 25px;
  }
  .footer-link {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    font-weight: normal;
    a {
      text-decoration: none;
      color: $color_1;
      // opacity: 0.75;
      display: block;
      padding-bottom: 20px;
      padding-top: 2px;
      cursor: pointer;
    }
  }
  .social-icons {
    display: inline-block !important;
    padding-bottom: 8px !important;
    margin-right: 15px;
  }
  .bottom-footer {
    padding: 20px 0 0;

    .copyright {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      padding: 0;
      color: $color_1;
      text-align: center;
      opacity: 0.75;
    }

    .scc-footer-logo a {
      font-size: 1.5rem;
      color: $color_1;
      margin-bottom: 15px;
      display: block;
    }

    .botm-menu {
      text-align: center;

      a {
        color: $color_1;
        text-decoration: none;
        font-weight: 500;
      }
    }
  }

  .acor-foot {
    margin: 5px 0;
    border-radius: 0;
    background-color: #3a4f68;
    color: $color_1;
    box-shadow: 0 0.3rem 1.2rem rgb(0 0 0 / 15%) !important;
    border: 1px solid $color_2;

    .acor-summ {
      min-height: 38px;
    }

    .acor-ico {
      color: $color_1;
    }

    .MuiAccordionSummary-content {
      margin: 5px 0;
    }

    .MuiAccordionDetails-root {
      padding: 20px 16px 0px;
      // border-top: 1px solid;
    }
  }
}

.icons {
  display: flex;
  grid-template-columns: repeat(5, auto);
  align-items: center;

  a {
    width: 35px !important;
    height: 35px !important;
    border: 1px solid #ffffff;
    // padding: 5px 8px 8px 7px;
    border-radius: 50px;
    // line-height: 1;
    margin-right: 15px;
    text-align: center;
  }
}

.icons-social {
  display: inline-grid;
  grid-template-columns: repeat(5, auto);

  a {
    width: 35px !important;
    height: 35px !important;
    border: 1px solid #ffffff;
    text-align: center;
    border-radius: 50px;
    margin-right: 15px;

    svg {
      font-size: 20px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .mrg-top {
    margin-top: 2rem !important;
  }
}
@media (min-width: 768px) and (max-width: 2200px) {
  // .footer {
  //   margin: 6% 12% 2% 12%;
  // }
  .col-lg-4.mt-3.d-inline {
    display: inline;
  }
  .footer-main-div {
    display: flex;
    justify-content: space-between;
  }
  .socail-div {
    margin-left: 4rem;
  }
}

.usefule-links {
  li {
    display: flex;
    border-bottom: 1px solid #ffffff30;
    i {
      line-height: 1.8;
    }
  }
  a {
    padding-left: 0.8rem;
    padding-bottom: 0.6rem !important;
  }
}

.icons p {
  margin-bottom: 0px;
}

.costmize-icon-footer svg {
  width: 32px;
}
