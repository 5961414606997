.reviews {
  //   margin: 2% 10% 3% 10%;
  position: relative;

  color: black !important;
  .review-card {
    margin-right: 1%;
    display: flex !important;
    background-color: #778da9;
  }
  .prof-img {
    height: 80px;
    width: 80px;
  }
}
.review-body {
  justify-content: center;
}
.card-img-reviews {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.reviews .card-text p {
  margin-bottom: 0;
  font-size: 16px;
  text-align: justify;
  color: #ffffff;
  font-weight: 400;
}
.card-img-reviews p {
  color: #ffffff;
  font-weight: 500;
}
.card-text b {
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
}

.reviews .card-footer {
  padding: 0.5rem 1rem;
  background-color: unset;
  border-top: none;
}
.reviews .text-muted {
  color: #ffffff !important;
}
.card-img-reviews .overlay img,
svg {
  width: 20px;
}
.top-title h2 {
  margin-bottom: 2rem;
}

.reviews-body .img-name-div {
  display: block;
  color: #717171;
}
.reviews-body .card-img-reviews {
  background: white;
  padding: 0.5rem;
}
.reviews-body .card-body {
  padding: 1rem;
  min-height: 160px;
}

.reviews-body .card {
  background-color: #3a4f68 !important;
  padding: 0px;
}
// .full-reviews-card-div
//  path {
//     color: #013c4d;
// }

.full-reviews-card-div svg {
  width: 20px;
  height: auto;
}
.full-reviews-card-div h5 {
  margin: 0;
}
span.style-module_emptyIcons__Bg-FZ.empty-icons {
  color: #d5d5d5 !important;
}

.inner-reviews-card .card {
  background-color: #ffffff !important;
  padding: 0px;
}
.inner-reviews-card .profile-diteal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 0.5rem;
}
.inner-reviews-card .profile-diteal p {
  margin-bottom: 0;
  margin-left: 16px;
  font-weight: 500;
}

.inner-reviews-card .reviews .prof-img {
  height: 40px;
  width: 40px;
}
.inner-reviews-card .image-card-div2 {
  height: 150px;
  background-color: red;
}
.inner-reviews-card .card-text b {
  font-size: 18px;
  color: #263a4a;
  font-weight: 400;
}
.inner-reviews-card .image-card-div2 img {
  width: 150px;
  height: 150px;
}
.inner-reviews-card .card-body .card-text {
  font-weight: 400;
  text-align: justify;
}

// .inner-reviews-card  dropdown
.inner-reviews-card .menu-title {
  cursor: pointer;
}

.inner-reviews-card .more-filter-menu {
  border: 1px solid #e4e5e7;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #222325;
  line-height: 24px;
  min-height: 48px;
  min-width: 50px;
  padding: 12px 16px;
  -webkit-transition: border-color 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: border-color 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.inner-reviews-card .more-filter-menu:hover {
  border: 1px solid #222325;
}

.menu-title.more-filter-menu {
  position: relative;
}
.menu-content {
  position: absolute;
  max-width: 180px;
  z-index: 99;
  background-color: red;
  padding: 1rem;
  word-wrap: break-word;
  margin-top: 10px;
}
.inner-reviews-card select {
  width: 150px;
}
.reviews .card-body .white-color {
  color: #fff;
}

.img-name-div h5 {
  color: #3d405b;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  text-transform: uppercase;
  font-family: Montserrat,sans-serif!important;
}
.card-text.white-color b {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  text-transform: uppercase;
  font-family: Montserrat,sans-serif!important;
}
p.card-text.white-color {
  color: #3d405b;
  font-size: 16px;
  font-family: Montserrat,sans-serif!important;
}
.card-footer small {
  color: #3d405b;
  font-size: 16px;
  font-family: Montserrat,sans-serif!important;
}