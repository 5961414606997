$color_1: #2a578d;
$color_2: #fff;

.profile-cart {
  .profile-cart-img {
    img {
      width: 100px;
      height: 100px;
      padding: 5px;
      border-radius: 100%;
    }
  }
  .profile-header-detail {
    color: $color_1;
    h6 {
      color: #3d405b;
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 0.2rem;
      min-height: 0;
      font-family: Montserrat, sans-serif !important;
    }
  }
  .wallet-btn {
    width: 100%;
    font-weight: 500;
    border: 1px solid #fff;
    border-radius: 10px;
    color: $color_2;
    padding: 10px;
    background: #2a578d;
  }
  .edit-btn {
    width: 100%;
    font-weight: 500;
    border: 1px solid #fff;
    border-radius: 10px;
    color: $color_2;
    padding: 10px;
    background: #2a578d;
  }
}
@media (max-width: 320px) {
  .profile-cart {
    .profile-cart-img {
      img {
        width: 90px;
        height: 90px;
      }
    }
    .profile-header-detail {
      h6 {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 750px) and (max-width: 1980px) {
  .profile-header-detail h6 {
    font-size: 1.2rem;
  }
  .profile-cart {
    margin: 5% 10% 6% 10%;
    border: 1px #31323b solid;
    border-radius: 8px;

    // .edit-btn {
    //   width: 61%;
    //   font-weight: 500;
    //   border: 1px solid #fff;
    //   border-radius: 10px;
    //   color: #fff;
    //   //   padding: 10px;
    //   background: #2a578d;
    //   margin: 5% 10% 10% 35%;
    // }
    // .wallet-btn {
    //   width: 81%;
    //   font-weight: 500;
    //   border: 1px solid #fff;
    //   border-radius: 10px;
    //   color: #fff;
    //   //   padding: 10px;
    //   background: #2a578d;
    //   margin: 7% 0% 0% 46%;
    // }
  }
}
// irfan work
@media (min-width: 320px) and (max-width: 767px) {
  .profile-cart {
    border: 1px solid #2a578d;
    border-radius: 8px;
  }
  .profile-cart .edit-btn {
    padding: 6px;
    font-size: 14px;
  }
  .profile-cart .wallet-btn {
    padding: 6px;
    font-size: 14px;
  }

  .id-card-det {
    display: flex;
  }
  .id-card-btns {
    display: flex;
  }
  .col-12.id-card {
    display: block !important;
  }
}
.col-12.id-card {
  display: flex;
}
.col-12.col-md-6.id-card-det {
  display: flex;
}
.col-12.id-card-btns {
  display: flex;
  align-items: center;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .profile-header-detail h6 {
    font-size: 1rem !important;
  }
}

.profile-cart .profile-header-detail {
  word-wrap: break-word;
}
@media (min-width: 320px) and (max-width: 767px) {
  .profile-header-detail h6 {
    font-size: 14px !important;
  }
}
