.complete {
  border: 2px solid;
  // margin: 2%;
  border-radius: 6px;
  border-color: #2a578d;
  color: #2a578d;
  box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .feedbackButton {
    align-self: center;
  }
  .feedBut {
    font-family: math;
    color: #ffffff;
    border-radius: 11px;
    background: #2a578d;
    border-color: #2a578d;
  }
}
.overlay {
  position: inherit;
  display: block;
  width: 92%;
  height: 100%;
  top: 1%;
  left: 0;
  right: 0;
  border: 5px solid;
  border-radius: 14px;
  padding: 12px;
  color: #2a578d;
  margin: 0% 3% 3% 4%;
  bottom: 0;
  background-color: rgb(254 254 254 / 50%);
  text-align: center;
  padding: 2%;
  z-index: 1;
  cursor: pointer;
  button {
    background-color: rgb(42, 87, 141);
    color: white;
    padding: 1%;
    border-radius: 6px;
    border-color: #2a578d;

    width: 38%;
  }
}
.index-btn {
  border: 1px solid #2a578d;
  border-radius: 19px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.18);
  width: 15%;
}

.completed-session {
  /*margin: 1.5rem 0rem;*/
}
.index-btn {
  border: 1px solid #2a578d;
  border-radius: 19px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.18);
  width: 3.5%;
}

.col.ses-detail {
  text-align: left;
}
.col.feedbackButton {
  text-align: end;
}
.complete {
  width: auto;
  padding: 15px;
}

.overlay h3 {
  color: #3d405b;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif !important;
}
.overlay img,
svg {
  vertical-align: middle;
  // width: 33px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-color: #000;
}
// .index-btn {
//   border: 1px solid #2a578d;
//   border-radius: 19px;
//   box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.18);
//   width: 3.5%;
// }

.top-skill-ditel-box {
  border: 1px solid;
  padding: 1rem;
}
.top-skill-ditel-box .feedbackButton.col-4 {
  align-self: center;
}
.top-skill-ditel-box .ses-detail.col-8 {
  text-align: left;
}

.col-12.top-skill-ditel-box {
  border-radius: 8px;
  border: 2px solid #d9d8db;
  background-image: linear-gradient(to top, #fff 0%, #fff 50%, #a6b4ff 250%);
}
.top-skill-ditel-box button {
  border: 1px solid #d9d8db;
  background-color: white;
  border-radius: 5px;
  padding: 7px;
  font-weight: 500;
  color: #3a4f68;
}

.top-skill-ditel-box .info-detail {
  font-size: 16px;
  font-weight: 500;
  color: #3a4f68;
  margin-left: 5px;
  text-transform: capitalize;
  display: block;
}
.top-skill-ditel-box button:hover {
  background-color: #283340;
  color: #fff;
}
.top-skill-ditel-box span {
  color: #3d405b;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif !important;
}
.top-skill-ditel-box button:focus {
  outline: unset;
  outline: unset;
}
.completed-session .index-btn {
  box-shadow: 0 0 4px 2px rgb(223, 228, 233) !important;
  background-image: linear-gradient(to top, #fff 0%, #fff 50%, #a6b4ff 250%);
}

.completed-session .index-btn:focus {
  // outline: 2px solid #d2d6f1;
}
.completed-session .complete {
  border-radius: 8px;
  border: 0px solid #d9d8db;
  background-image: linear-gradient(to top, #fff 0%, #fff 50%, #a6b4ff 250%);
}

.completed-session h3 {
  margin-bottom: 2rem;
  font-size: 32px;
  color: #2a578d;
}

.feedback-sessions h3 {
  font-size: 32px;
  color: #2a578d;
  text-align: center;
  margin-bottom: 2rem;
}

@media screen and (max-width: 567px) {
  .top-skill-ditel-box button {
    padding: 4px;
    font-size: 14px;
  }
  .completed-session h3 {
    font-size: 24px;
  }

  .feedback-sessions h3 {
    font-size: 24px;
  }
}

.col-12.top-skill-ditel-box {
  min-height: 195px;
}

h3.Feedback-heading {
  font-size: 32px;
  color: #2a578d;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.top-skill-ditel-box p {
  /* text-transform: capitalize; */
  color: #3d405b;
  font-size: 16px;
  font-family: Montserrat, sans-serif !important;
}

.ses-detail b {
  color: #3d405b;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif !important;
}
