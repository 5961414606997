.App {
  font-family: sans-serif;
  text-align: center;
  width: 100%;
}
.holder {
  cursor: pointer;
}
img {
  max-width: 100%;
  height: auto;
}

.index-btn {
  border: 1px solid #ffffff !important;
  box-shadow: 0 0 4px 2px rgb(42 87 141) !important;
  width: 30px !important;
  height: 30px;
  margin-left: 10px;
  background-color: white;
}

button.show-more-btn {
    border: none;
    margin: 10px 0px 0;
    padding: 4px 10px;
    background: linear-gradient(315deg, #8d8d8d, #437fc7 74%);
    color: #fff;
    border-radius: 5px;
}