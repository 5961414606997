.card-body.experts{
    padding: 0 !important;
    .our-expert-page-div {
        background: lavender;
        padding: 2rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;
        border-radius: 4px;

    
    .img-content-div {
        align-items: center;
        display: flex;
         img {
            width: 100px;
        }
        .id-owner-name-div {
            padding-left: 2rem;
             h5 {
                font-size: 32px;
                text-transform: capitalize;
                color: #2a578d;
            }
             span {
                font-size: 18px;
                text-transform: capitalize;
                color: #717171;
            }
        }
    }
    .full-discribtion{
        p {
            margin-top: 1rem;
            font-size: 18px;
            text-align: justify;
            color: #616161;
        }
        span {
            display: block;
            color: #616161;
        }
    }

    .dittel-lessons {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        padding: 0.6rem 0.45rem;
        background-color: #f7f8fa;
        width: 100%;
        border-radius: 4px;
         span {
            font-size: 1.5rem;
            font-weight: 600;
            margin-right: 20px;
            text-align: center;
            color: #1cac81;
        }
    }
    .dittel-ratings {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        padding: 0.6rem 0.45rem;
        background-color: #f7f8fa;
        width: 100%;
        border-radius: 4px;
        margin-top: 1rem;
         span {
            font-size: 1.5rem;
            font-weight: 600;
            margin-right: 20px;
            text-align: center;
            color: #1cac81;
        }
    }
    span.icon-titel {
        font-size: 14px !important;
        font-weight: 400 !important;
        color: #656e7a !important;
        margin-right: 0px !important;
        margin-left: 8px;
    }
}
}
.img-titel-div2 {
    background-color: #2a578d;
    display: flex;
    padding: 0.5rem;
    align-items: center;
}
.name-ditels {
    padding: 8px;
}
.body-summery .card-text{
    color: #3d405b;
font-size: 16px;
font-family: Montserrat,sans-serif!important;
}
.name-ditels P {
    color: #ffffff;
    font-size: 1rem;
    font-family: Montserrat,sans-serif!important;
    font-weight: 600;
    margin-bottom: 0.2rem;
}
.body-summery{
    background-color: white;
    .card-text b {
        font-size: 18px;
        color: #013c4d;
        font-weight: 500;
    }
}
@media (min-width: 320px) and (max-width: 767px){
    .card-body.experts .our-expert-page-div {
        display: block;
      
    }
    .border-top1{
        margin-top: 1rem;
    border-top: 1px solid #717171;
    padding-top: 1rem;
    }
}





