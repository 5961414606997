$color_1: #3a4f68;
$color_2: #2c4c71;
$color_white: white;
$background-color_1: #3a4f68;
$border-color: #3a4f68;

.i-posi {
  position: relative;

  i {
    position: absolute;
    top: 14%;
    right: 4%;
    color: #2a578d;
  }
}

.valid-txt {
  color: red !important;
  text-align: left;
  font-size: 14px !important;
  margin: 5px 0 5px 5px;
}

.slide-controls {
  margin-top: 15px;
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  border: 1px solid $border-color;
  border-radius: 20px;
  .slide {
    height: 100%;
    width: 100%;
    color: $color_white;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.6s ease;
  }
  label.signup {
    color: $color_1;
  }
  .slider-tab {
    position: absolute;
    height: 100%;
    width: 50%;
    left: 0;
    z-index: 0;
    border-radius: 15px;
    background-color: $background-color_1;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
}

.register-radio {
  input[type="radio"] {
    display: none;
  }
}

#signup {
  &:checked ~ .slider-tab {
    left: 50%;
  }
  &:checked ~ label.signup {
    color: $color_white;
    cursor: default;
    user-select: none;
  }
  &:checked ~ label.login {
    color: $color_1;
  }
}
#login {
  &:checked ~ label.login {
    color: $color_white;
    cursor: default;
    user-select: none;
    cursor: default;
    user-select: none;
  }
  &:checked ~ label.signup {
    color: $color_1;
  }
}
.bg-image {
  background-color: #3a4f68;
  height: 300px;
  border-radius: 0px 0px 20px 20px;
}

.text-mute {
  color: $color_white;
}
.formcard {
  margin-top: -100px;
  margin-bottom: 50px;
  border-radius: 15px;
  background: hsla(0, 0%, 100%, 0.8);
  backdrop-filter: blur(30px);
  box-shadow: 1px 1px 8px 0px #cdcbcd;
}
.bg-blue {
  background-color: $background-color_1;
}
.title {
  text-align: center;
  font-size: 80px;
  letter-spacing: -6px;
  font-weight: 800;
  color: $color_white;
  padding: 30px;
}
.favicons {
  text-align: center;
  font-size: 44px;
  svg {
    margin: 0 15px;
  }
}
.card-border {
  padding: 2rem 2rem;
}
.reg-btn {
  color: $color_white !important;
  background-color: $background-color_1 !important;
  border-color: $border-color !important;
}
.Regibtn {
  background: $background-color_1;
  color: $color_white;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid $border-color;
  border-radius: 6px 6px 6px 6px;
  padding: 10px;
}

.form-center {
  width: 50%;
  form {
    margin: 15px;
  }
}
.btn {
  .btn-primary {
    border-radius: 30px;
  }
}

.form_foot {
  text-align: center;

  p {
    position: relative;
    border: 2px solid $border-color;
    border-radius: 50px;
    width: fit-content;
    padding: 0px 10px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 600;

    &::after {
      margin: 15px;
      content: "";
      width: 70px;
      height: 2px;
      background: $color_1;
      position: absolute;
      left: 41px;
      margin: 0 auto;
      bottom: 9px;
      border-radius: 20px;
    }

    &::before {
      margin: 15px;
      content: "";
      width: 70px;
      height: 2px;
      background: $color_1;
      position: absolute;
      right: 41px;
      margin: 0 auto;
      bottom: 9px;
      border-radius: 20px;
    }
  }
}

.form_foot-nw {
  text-align: center;

  p {
    position: relative;
    border: 2px solid $border-color;
    border-radius: 50px;
    width: fit-content;
    padding: 0px 10px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 600;

    &::after {
      margin: 15px;
      content: "";
      width: 70px;
      height: 2px;
      background: $color_1;
      position: absolute;
      left: 91px;
      margin: 0 auto;
      bottom: 9px;
      border-radius: 20px;
    }

    &::before {
      margin: 15px;
      content: "";
      width: 70px;
      height: 2px;
      background: $color_1;
      position: absolute;
      right: 91px;
      margin: 0 auto;
      bottom: 9px;
      border-radius: 20px;
    }
  }
}

.form_foot-log {
  text-align: center;

  p {
    position: relative;
    border: 2px solid $border-color;
    border-radius: 50px;
    width: fit-content;
    padding: 0px 10px;
    margin: 0 auto;
    font-size: 12px;
    font-weight: 600;

    &::after {
      margin: 15px;
      content: "";
      width: 45px;
      height: 2px;
      background: $color_1;
      position: absolute;
      left: 160px;
      margin: 0 auto;
      bottom: 9px;
      border-radius: 20px;
    }

    &::before {
      margin: 15px;
      content: "";
      width: 45px;
      height: 2px;
      background: $color_1;
      position: absolute;
      right: 160px;
      margin: 0 auto;
      bottom: 9px;
      border-radius: 20px;
    }
  }
}

.fom-lnk {
  background-color: #0000;
  font-weight: 600;
  color: $color_1;
  outline: none !important;
  padding: 5px 15px;
  border: 2px solid $color_1;
  border-radius: 63px;
  width: -webkit-fill-available;
}
.display {
  display: block;
}
@media screen and (max-width: 850px) {
  .login-btn button {
    font-size: 16px !important;
    font-weight: 500;
    width: 150px !important;
  }
  .create-btn button {
    width: 200px !important;
  }
  .formcard {
    margin-top: -100px;
    margin-right: 3rem !important;
    margin-left: 3rem !important;
    box-shadow: 1px 1px 8px 0px #cdcbcd;
  }
  .form-center {
    width: 100%;
  }
  .title {
    text-transform: capitalize;
    margin-bottom: 0.75rem;
    color: $color_white;
  }
}
@media screen and (min-width: 768px) {
  .login-center-div {
    display: flex;
    justify-content: center;
  }
  .login-50 {
    width: 50%;
  }
}
.login-center-div svg {
  width: auto;
}
.login-btn button {
  font-size: 18px;
  font-weight: 500;
  width: 180px;
}
.login-btn {
  justify-content: center;
}
.create-btn button {
  width: 240px;
}
.reg-link {
  border-radius: 10px;
  text-decoration: underline;
  color: #3a4f68;
  cursor: pointer;
}
.login-center-div svg {
  width: auto;
  cursor: pointer;
}
