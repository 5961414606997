$color_1: #2a578d;
$color_2: #545454;

.fade.modal.show {
  padding-left: 0px !important;
}

.modal {
  backdrop-filter: blur(3px);
}

.modal-dialog-pop {
  margin: 5%;
  max-height: 95%;
}

.modal-content-pop {
  border: none;
  border-radius: 15px;
  padding: 0px;
  max-height: 100%;
}

.counsellor .modal-body {
  border: 1px solid;
    border-radius: 4px;
    padding: 8px;
  height: 240px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    color: $color_2;
    font-size: 16px;
  }
}

.modal-header {
  .btn-close {
    // margin: -0.5rem 0.5rem -0.5rem auto;
    padding: 5px;
    border: 1px solid;
    border-radius: 50%;
    position: absolute;
    font-size: 10px;
    right: 16px;
    top: 16px;
  }
  margin-bottom: 1rem;
  border: none;
  padding: 0px !important;
}

.modal-title {
  margin-bottom: 1rem;
  padding: 10px 15px;
  width: 100%;

  h6 {
    color: $color_1;
  }

  .row {
    .col-4 {
      img {
        width: 80%;
      }
    }
  }

  label {
    color: $color_1;
    font-size: 16px;
    font-weight: 600;
    display: block;
  }

  .col-4 {
    h5 {
      font-size: 16px;
    }
  }
}

// .rating {
// 	img {
// 		width: 12px;
// 	}
// }
.feedback {
  h6 {
    color: $color_1;
    font-size: 13px;
  }

  .card {
    box-shadow: 0px 1px 4px 0px #0000002e;
  }

  .card-body {
    .col-9 {
      p {
        font-size: 10px;
        color: $color_2;
        padding: 0;
      }

      img {
        float: left;
      }

      span {
        float: right;
        font-size: 12px;
        color: $color_1;
      }
    }

    padding: 5px;

    .col-3 {
      img {
        width: 100%;
      }
    }
  }
}

.coun-detail {
  padding: 0 10px;

  label {
    font-size: 13px;
    margin-bottom: 5px;
    line-height: 1.1;
  }

  h5 {
    font-size: 12px;
    overflow-wrap: anywhere;
  }

  p {
    display: inline-block;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
  }

  .modal-title {
    line-height: 1;
  }
}

@media (max-width: 375px) {
  .modal-title {
    label {
      color: $color_1;
      font-size: 16px;
      font-weight: 600;
      display: block;
    }
  }

  .feedback {
    .card-body {
      .col-9 {
        p {
          font-size: 10px;
        }

        span {
          font-size: 10px;
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 576px){
  .modal-dialog .top-title h2 {
    font-size: 24px !important;
}
  .modal-dialog .coun-detail label {
    font-size: 16px !important;
}
.modal-dialog .modal-content-pop h6 {
  font-size: 18px !important;
}
}
@media (max-width: 320px) {
  .modal-title {
    label {
      color: $color_1;
      font-size: 16px;
      font-weight: 600;
      display: block;
    }
  }

  .feedback {
    .card-body {
      .col-9 {
        p {
          font-size: 10px;
        }

        span {
          font-size: 10px;
        }
      }
    }
  }
}
.modal-content .star-svg {
  // color: #b6b6b6;
  width: 20px;
}
@media (max-width: 768px) {
  .card {
    height: 100%;
  }
  .card-title {
    font-weight: 600;
    margin: 0;
    min-height: 60px;
    overflow: hidden;
  }
  .skills-card-title {
    color: black;
    font-weight: 900;
    // z-index: 999 !important;
    margin: 0;
    min-height: 60px;
    overflow: hidden;
  }
  .rating {
    display: block;
    margin-bottom: 0px;
  }
}
.skills {
  margin-top: 3% !important;
}
