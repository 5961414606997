$color_1: #2a578d;
$color_2: #545454;
$color_3: #fff;
$background-color_1: #2a578d;
$background-color_2: #fff;

.sess-clr {
  strong {
    color: $color_1;
    font-weight: bold !important;
  }
}

.session-detailing {
  // border: 1px solid #2a578d;
  border-radius: 5px;
  margin: 10px;
  //box-shadow: 0px 0px 4px 2px #0000002e;
  h6 {
    margin: 0;
    text-align: left;
    color: #3d405b;
    font-size: 1rem;
    font-weight: 600;
    min-height: 0;
    font-family: Montserrat,sans-serif!important;
  }
  p {
    text-align: left;
    margin: 0;
    color: #3d405b;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
    min-height: 0;
    font-family: Montserrat,sans-serif!important;
  }
  .session-more-detail {
    position: relative;
    //border-top: 1px solid #2a578d;
    padding: 15px;
    .session-dtl-list-1{
      background-color: #fff;
      padding: 8px;
      border-radius: 4px;
      margin-right: 8px;
      border: 2px solid #2a578d;
      // width: 300px;
    }
    .session-dtl-list{
      background-color: #fff;
      padding: 8px;
      border-radius: 4px;
      margin-right: 8px;
      border: 2px solid #2a578d;
    }
    .bid-btn {
      display: flex;
      justify-content: center;

      .accept-btn {
        // position: absolute;
        bottom: 54%;
        right: 10px;
        border: 2px solid #2a578d;
        border-radius: 5px;
        background-color: $background-color_1;
        color: $color_3;
        font-size: 14px;
        padding: 8px 8px 8px 8px;
        font-weight: 500;
        width: 150px;
        margin-top: 4px;
      }
      .reject-btn {
        // position: absolute;
        bottom: 18%;
        right: 10px;
        border: 2px solid $color_1;
        border-radius: 5px;
        background-color: $background-color_2;
        color: $color_1;
        font-size: 14px;
        padding: 7px 8px 8px 8px;
        font-weight: 500;
        width: 150px;
        margin-top: 4px;
        margin-left: 8px;
      }
    }
  }
  .top-border {
    border-top: 1px solid $color_1;
  }
}

@media (min-width: 991px) {
  .session-detailing {
    width: 60%;
  }
  .request-confirm-div {
    justify-content: center;
    display: flex;
  }
}

.session-box .edit-btn {
  bottom: 54%;
  right: 10px;
  border: none;
  border-radius: 5px;
  background-color: #2a578d;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 150px !important;
  padding: 8px;
  margin-top: 1rem;
}

.session-box {
  .session-dtl-list-1 {
    background-color: #fff;
    padding: 8px;
    border-radius: 4px;
    border: 2px solid #2a578d;
    // margin-right: 8px;
  }
    .session-dtl-list {
      background-color: #fff;
      padding: 8px;
      border-radius: 4px;
      border: 2px solid #2a578d;
      // margin-right: 8px;
  }
}

.request-confirm-div {
  display: block;
}

// .main-head {  
//   font-size: 32px !important;
//   color: #2a578d;
// }

// .main-head::after {
//   content: "";
//   border-bottom: 4px solid #2a578d;
//   display: block;
//   margin-top: 5px;
//   width: 80px;
//   border-radius: 10px;
// }

@media (max-width: 767px){
  .session-detailing .session-more-detail .bid-btn {
    display: flex;
    justify-content: center !important;
    margin-top: 1rem;
}
  .session-dtl-list {
    text-align: center;
}
  .session-box.p-3 h6 {
    font-size: 24px !important;
}
  .sess-clr.d-flex{
    display: block!important;
  }
  
  .session-dtl-list-1{
    margin-bottom: 0.5rem;
  }

}
// .sess-clr{
//   padding: 0px !important; 
// }
.schedule-btn {
  margin-right: 1rem;
  bottom: 54%;
  right: 10px;
  border: none;
  border-radius: 5px;
  background-color: #3d405b !important;
  color: #fff !important;
  font-size: 14px;
  padding: 8px 8px 8px 8px;
  font-weight: 500;
  width: 150px;
  margin-top: 4px;
}
