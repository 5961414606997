$color_1: #2a578d;
$color_2: #545454;
$color_3: #fff;
$color_4: #8d8d8d;
$color_5: white;
$background-color_1: #545454;
$background-color_2: #2a578d;

.form-sec-2 {
  display: none;
}
.form-sec-3 {
  display: none;
}
.form-sec-4 {
  display: none;
}

.check-skill {
  label {
    padding:4px 8px;
    border-radius: 8px;
    margin-right: 5px;
    color: $color_5;
    background-color: #2a578d;
    margin-bottom: 5px;
  }
  input[type="radio"] {
    display: none;
  }
}

#result {
  margin-bottom: 10px;
  padding: 4px 8px;
  border-radius: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: white;
  background-color: #2a578d;
  font-size: 16px;
  
  button {
    background: transparent;
    color: #fff;
    border-radius: 50px;
    padding: 0px 7px;
    margin-left: 10px;
    font-weight: 500;
    border: 1px solid #fff;
  }
}
.next-btn {
  margin: 0;
  margin-top: 12px;
  background: $color_1;
  border-radius: 15px;
  padding: 2px 25px;
  color: $color_3;
}
input[type="button"] {
  display: none;
}
.bid-list {
  margin-top: 8px;
  padding: 5px;

  display: flex;
  align-items: center;
}
.bid-item {
  padding: 6px;
  border-radius: 4px;
  margin: 5px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 10%);
  list-style: none;
  width: 92%;
  padding-left: 10px;
  border: 0;
}

.valid-sess {
  p {
    color: red;
    font-size: 12px;
    margin-bottom: 0;
    padding-left: 15px;
  }
}

.ad-sub-btn {
  background: $color_1;
  border: 2px solid;
  border-radius: 10px;
  display: flex;
  margin-right: 8%;
  font-size: 1rem;
  color: white !important;
  .ad-sub-btn-clr {
    color: white !important;
    font-size: 24px;
  }
}

@media (max-width: 520px) {
  .new-session h6 {
    font-size: 24px !important;
  }
  .new-session {
    h6 {
      color: $color_1;

      &::before {
        width: 50px;
        border-bottom: 2px solid $color_1;
      }
    }
    .session-label {
      color: $color_2;
      display: block;
    }
    input[type="text"] {
      display: block;
      width: 100%;
      padding: 3px;
      border: none;
      box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 10%);
      padding-left: 12px;
      &:focus {
        // border: 1px solid #437fc74d;
        outline-offset: 0px;
        outline: none;
      }
      &::placeholder {
        color: $color_4;
        opacity: 1;
        font-size: 16px;
      }
      &:-ms-input-placeholder {
        color: $color_4;
        font-size: 16px;
      }
      &::-ms-input-placeholder {
        color: $color_4;
        font-size: 16px;
      }
    }

    textarea {
      display: block;
      width: 100%;
      padding: 3px;
      border: none;
      color: black;
      box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 10%);
      padding-left: 12px;
      &:focus {
        // border: 1px solid #437fc74d;
        outline-offset: 0px;
        outline: none;
      }
      &::placeholder {
        color: $color_4;
        opacity: 1;
        font-size: 16px;
      }
      &:-ms-input-placeholder {
        color: $color_4;
        font-size: 16px;
      }
      &::-ms-input-placeholder {
        color: $color_4;
        font-size: 16px;
      }
    }
  }
  .next-btn {
    margin-left: 8px;
    background: $color_1;
    color: $color_3;
    padding: 4px 28px;
    border: none;
    border-radius: 15px;
  }
}

@media (min-width: 521px) {
  .new-session {
    h6 {
      color: $color_1;

      &::before {
        width: 50px;
        border-bottom: 2px solid $color_1;
      }
    }
    .session-label {
      color: $color_2;
      display: block;
    }
    input[type="text"] {
      display: block;
      width: 100%;
      // margin-left: 6px;
      padding: 3px;
      border: none;
      box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 10%);
      padding-left: 6px;
      &:focus {
        // border: 1px solid #437fc74d;
        outline-offset: 0px;
        outline: none;
      }
      &::placeholder {
        color: $color_4;
        opacity: 1;
        font-size: 16px;
      }
      &:-ms-input-placeholder {
        color: $color_4;
        font-size: 16px;
      }
      &::-ms-input-placeholder {
        color: $color_4;
        font-size: 16px;
      }
    }
    textarea[type="text"] {
      display: block;
      width: 100%;
      // margin-left: 6px !important;
      padding: 3px;
      border: none;
      box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 10%);
      padding-left: 6px;
      color: #000;
      &:focus {
        // border: 1px solid #437fc74d;
        outline-offset: 0px;
        outline: none;
      }
      &::placeholder {
        color: $color_4;
        opacity: 1;
        font-size: 16px;
      }
      &:-ms-input-placeholder {
        color: $color_4;
        font-size: 16px;
      }
      &::-ms-input-placeholder {
        color: $color_4;
        font-size: 16px;
      }
    }
  }
  .next-btn {
    background: #2a578d;
    color: #fff;
    padding: 4px;
    border: none;
    border-radius: 8px;
    width: 80px;
    text-align: center;
}
}

// new pasted code new session
.new-session {background-color: #f8f9fa;
padding: 1rem;
border-radius: 8px;
}
.new-session h6 {
font-size: 2rem;
}
.new-session .session-label {
font-size: 20px !important;
}
.new-session .session-label {
padding-bottom: 0.5rem;
}

.new-session .form-sec-2, .form-sec-3 {
margin-top: 2rem;
}

.bid-item {
width: 100%;
}
.new-session input[type=text] {
width: 100%;
padding: 6px;
}
/*.ad-sub-btn {
background: #2a578d;
border: 2px solid;
border-radius: 10px;
display: flex;
margin-right: 0;
font-size: 1rem;
color: white !important;
padding: 8px;
width: 100px;
justify-content: center;
}*/

.ad-sub-btn {
  background: #2a578d;
  border: 2px solid;
  border-radius: 100px;
  display: flex;
  margin: 0 !important;
  font-size: 1rem;
  color: white !important;
  padding: 1px;
  width: 30px;
  height: 30px;
  justify-content: center;
}

.cust-btn{
  font-size: 1.5rem !important;
  line-height: 0.8 !important;
}

.cust-skill{
  background-color: #fff !important;
  border: 1px solid #2a578d !important;
  color: #2a578d !important;
}

.bid-list {
  align-items: baseline;
  margin: 0;
}

.add-btn{
  display: flex!important;
  justify-content: center;
}
 
.new-session{
  box-shadow:0px 0px 4px 2px rgba(0, 0, 0, 0.1803921569) ;
}
.text-start-mdfy {
  text-align: start;
}

.text-black{
  color:#000 !important;
}

@media (max-width:820px){
  .add-btn {
    display: flex !important;
    justify-content: space-between;
  }
}