$color_1: #2a578d;

.section {
  position: relative;
}
.search-box {
  input:focus-visible {
    outline: none;
  }
  input[type="search"] {
    height: 50px;
    width: 450px;
    border-radius: 10px 0px 0px 10px;
    padding-left: 10px;
    border: none;
    color: #707070;
    font-size: 18px;
  }
  svg.search-icon {
    vertical-align: top;
    height: 50px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 60px;
    background-color: #2b2d42;
    padding: 8px 0px;
  }
  #FiSearch {
    height: 40px;
  }
}
@media (min-width: 520px) {
  .slider-cover-content {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(42, 87, 141, 0.6), #8d502a6e);
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 20px;
  }

  // .inn-txt {
  //   position: absolute;
  //   bottom: 0px;
  //   left: 70px;
  //   top: 20%;
  // }
}
@media (max-width: 320px) {
  .search-box svg.search-icon {
    vertical-align: bottom;
    height: 49px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    width: 12%;
    background-color: #363a7e;
  }
}
@media (max-width: 520px) {
  .slider-cover-content {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(42, 87, 141, 0.6), $color_1);
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 20px;
  }

  // .inn-txt {
  //   position: absolute;
  //   bottom: 15px;
  //   left: 15px;
  // }
}
@media (max-width: 767px) {
  .search-box {
    top: 45% !important;
  }
  .tag-heading {
    font-size: 2rem !important;
  }
  .search-box {
    position: relative;
    top: 50% !important;
    left: 45px;
    z-index: 9;
    display: inline-block;
    width: 100%;
  }
  .inn-txt {
    // position: absolute;
    // bottom: 0;
    // top: 20%;
    // left: 15px;
    h2 {
      font-size: 2rem;
    }
  }
  .search-box {
    left: 0px !important;
    input[type="search"] {
      height: 50px;
      width: 100%;
      border-radius: 8px 8px 8px 8px;
      padding-left: 10px;
      border: none;
      color: #707070;
      font-size: 18px;
    }
  }
  .search-box svg.search-icon {
    margin-top: 0.5rem;
    vertical-align: top;
    height: 50px;
    width: 100%;
    background-color: #2b2d42;
    border-radius: 8px;
  }
}
section.section {
  background-image: url(https://gumlet.assettype.com/freepressjournal/2023-10/e6f4e483-91c9-4ade-8fb6-faeb673e6f0e/Untitled_design__56_.jpg);
  height: 400px;
  background-size: cover;
  background-position: center;
}
.searchResults {
  display: none;
  position: relative;

  width: 70%;
  color: black;
  border-radius: 3px;
  justify-items: center;
  button {
    width: fit-content;
    background-color: #ffffff00;
    border-radius: 8px;
    margin: 4px;
    font-weight: 500;
    color: #ffffff;
    padding: 2px 8px;
    border: 1px solid;
  }
}
.slider-cover-content {
  position: absolute;
}
.skills-card-title p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2a578d;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
}

.top-title h2 {
  font-size: 32px;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.popular-card-slider .image-box-size img {
  width: 100px !important;
  height: 70px;
}
.popular-card-slider {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  height: 70px !important;
  padding: 0 !important;
  border-radius: 15px !important;
  background-color: white !important;
}
.tag-heading {
  font-size: 3rem;
}
.search-box {
  top: 55%;
}
