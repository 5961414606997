.info-partitions {
  background-color: white;
  border: none;
}
.work-info {
  // padding-bottom: 10%;
}
.guideline-headline {
  background-color: #a0c4cf !important;
  line-height: 2rem;
  font-size: larger;
  padding: 2%;
}
// .guideline-tag {
//   margin: 2%;
//   padding: 2%;
//   background-color: white;
//   border: inset;

//   line-height: 1.5rem;
// }
.hiw-header {
  text-align: center;
  padding: 8%;
  color: rgb(55 47 47);
}

.logo-back {
  height: 20%;
  border: black;
  border-radius: 54px;
  svg {
    height: 60px;
    width: 60px;
  }
  // :hover {
  //   background-color: rgb(209, 226, 226);
  // }
}

@media (min-width: 991px) {
  .logo-back {
    height: 40%;
  }
}
@media (max-width: 1200px) {
  .hiw-list:before {
    display: none !important;
  }
}

.guideline-headline h2 {
  color: #fff;
  font-size: 40px;
}
.guideline-headline p {
  color: #fff;
  font-size: 22px;
}

.guideline-tag {
  z-index: 1;
  position: relative;
  margin: 1rem;
  padding: 1rem;
}
.shadow-effect-2 {
  position: relative;
}
.v-tagline-box-v1 {
  border: 1px solid #eee;
  border-top: 2px solid #eee;
}
.v-tagline-box {
  padding: 25px 30px;
  background: #fff;
  margin-bottom: 30px;
}
.v-box-shadow:before {
  top: 80%;
  left: 5px;
  width: 50%;
  z-index: -1;
  content: "";
  bottom: 15px;
  max-width: 300px;
  background: #999;
  position: absolute;
}
.shadow-effect-2:before {
  transform: rotate(-3deg);
  box-shadow: 0 15px 10px #999;
}
.v-box-shadow:after {
  top: 80%;
  left: 5px;
  width: 50%;
  z-index: -1;
  content: "";
  bottom: 15px;
  max-width: 300px;
  background: #999;
  position: absolute;
}
.shadow-effect-2:after {
  transform: rotate(3deg);
  box-shadow: 0 15px 10px #999;
}
.shadow-effect-2:after {
  left: auto !important;
  right: 5px;
  transform: rotate(3deg);
}
.guideline-tag p {
  color: #717171;
}
.guideline-tag h5 {
  text-transform: capitalize;
  font-size: 28px;
  font-weight: 400;
}
.hiw-header {
  text-align: center;
  padding: 3rem;
  color: rgb(58 79 104);
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2rem;
}

.hover-efect-card-section {
  justify-content: center;
  display: flex;
}

.hover-efect-card-section .small,
small {
  font-size: 1.875em;
  color: #3a4f68;
}
.feature-box-icon.small.icn-holder:hover .logo-back {
  color: #fff;
}
.hover-efect-card-section .feature-box-icon {
  height: 60px;
  width: 60px;
  background-color: white;
  margin-right: 0px;
}
.hover-efect-card-section .feature-box-icon:hover,
.feature-box[class*="feature-box-secundary-"] .feature-box-icon {
  background-color: #3d405b !important;
  background-image: url(../../../images/crease.svg);
}
.hiw-list {
  position: relative;
}

.for-four-option.hiw-list:before {
  top: 74px;
  position: absolute;
  left: 120px;
  display: block;
  width: 80% !important;
  height: 0;
  border-top: 1px dashed #b1b1b1;
  content: "";
  top: 56px;
  z-index: 0 !important;
}
.info-partitions h5 {
  text-align: center;
  min-height: 50px !important;
  color: #3d405b;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif !important;
}
.feature-box-line {
  width: 50px;
  height: 2px;
  display: block;
  background: #e4e4e4;
  margin: 0 auto 0px;
}
.info-partitions p {
  text-align: center;
  padding: 10px;
  min-height: 152px;
  overflow: hidden;
  max-height: 0px;
  color: #3d405b;
  font-size: 16px;
  font-family: Montserrat, sans-serif !important;
}

@media (min-width: 320px) and (max-width: 576px) {
  .guideline-headline h2 {
    font-size: 32px;
  }
  .guideline-headline p {
    font-size: 16px;
    text-align: justify;
  }
  .guideline-tag h5 {
    font-size: 20px;
  }
  .guideline-tag {
    padding: 0rem;
  }
  .hiw-header {
    padding: 2rem;
  }
}

.info-partitions p {
  text-align: center;
  padding: 10px;
  min-height: 152px;
  overflow: hidden;
  max-height: 0px;
  color: #3d405b;
  font-size: 16px;
  font-family: Montserrat, sans-serif !important;
}
