$color_1: #437fc7;
$color_2: #2a578d;

.top-title {
  text-align: center;

  h2 {
    font-size: 18px;
    display: inline-block;
    position: relative;
    color: $color_2;
    // float: inline-start;

    &::after {
      margin: 15px;
      content: "";
      width: 60px;
      height: 3px;
      background: $color_2;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -10px;
      border-radius: 20px;
    }
  }

  h3 {
    font-size: 22px;
    display: inline-block;
    position: relative;
    color: $color_2;
    &::after {
      margin: 15px;
      content: "";
      width: 60px;
      height: 3px;
      background: $color_2;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -10px;
      border-radius: 20px;
    }
  }
}

.top-title-2 {
  text-align: center;

  h2 {
    font-size: 18px;
    display: inline-block;
    position: relative;
    color: $color_2;
    // top: 15px;

    &::after {
      margin: 15px;
      content: "";
      width: 60px;
      height: 3px;
      background: $color_2;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -10px;
      border-radius: 20px;
    }
  }

  h3 {
    font-size: 22px;
    display: inline-block;
    position: relative;
    color: $color_2;
    &::after {
      margin: 15px;
      content: "";
      width: 60px;
      height: 3px;
      background: $color_2;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -10px;
      border-radius: 20px;
    }
  }
}

@media (max-width: 567px) {
  h2.heading.faq-page-heading {
    font-size: 20px !important;
  }
}

.cookie-common .top-title h2::after {
  content: "";
  left: 0px;
}
