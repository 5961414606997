$color_1: #2a578d;
$color_2: #fff;
$background-color_1: #2a578d;

.studentprofile-pop {
  .modal {
    backdrop-filter: blur(3px);
  }

  .modal-header {
    border: none;
    padding: 0px;
  }

  .modal-content {
    margin-top: 1rem;
    color: $color_1;
  }

  .modal-body-div {
    // padding: 0;

    input {
      font-size: 16px;
      width: 100%;
      margin: 5px 0px;
      color: #000;
      border: 1px solid $color_1;
      border-radius: 5px;
      padding: 5px;

      &:focus-visible {
        outline-color: $color_1;
      }
    }
  }

  .bord-div {
    border: 1px solid #2a578d;
    padding: 5px;
    border-radius: 5px;
    margin: 4px 0;
  }

  input {
    &::placeholder {
      color: $color_1;
    }
  }

  textarea {
    height: 100px;
    resize: none;

    &::placeholder {
      color: $color_1;
    }
  }

  #uploadlabel {
    text-align: left;
    font-size: 16px;
  }

  .submit-btn {
    .btn {
      width: 50%;
    }
  }

  .btn-upload {
    float: right;
    font-size: 16px;
    padding: 4px 12px;
    background-color: $background-color_1;
    border-radius: 5px;
    color: $color_2;
    border: 1px solid $color_1;
  }

  input[type="file"] {
    &::-webkit-file-upload-button {
      display: none;
    }

    &::file-selector-button {
      display: none;
    }
  }

  .btn-submit {
    color: $color_1;
    border: 2px solid $color_1;
    border-radius: 5px;
    font-weight: 600;
    font-size: 18px;

    &:hover {
      color: $color_2;
      border: 2px solid $color_1;
      border-radius: 5px;
      background-color: $background-color_1;
    }
  }
}

select {
  font-size: 16px;
  width: 100%;
  margin: 5px 0px !important;
  color: #000;
  border: 1px solid $color_1;
  border-radius: 5px;
  padding: 5px;
}

textarea {
  font-size: 16px;
  width: 100%;
  margin: 5px 0px !important;
  color: #000;
  border: 1px solid $color_1;
  border-radius: 5px;
  padding: 5px;
}

.upl-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-dialog {
  display: flex;
  // align-items: center;
  // min-height: calc(100% - 1rem);
  max-height: 95%;
  margin: 5% !important;
}

.modal-body {
  &.pop {
    max-height: 100%;
    overflow: scroll;
  }
}

input#photo-upload {
  display: none;
}

.modal-content {
  border: none !important;
}

.up-img {
  width: 35%;
}

.searchWrapper {
  border: none !important;
  padding: 0px !important;
}

.chip {
  background: $color_1 !important;
}

.highlightOption {
  background: $color_1 !important;
}

.multiSelectContainer li:hover {
  background: $color_1 !important;
}

.optionListContainer {
  background: $color_2;
  border-radius: 4px;
  margin-top: 1px;
  position: inherit !important;
  width: 100%;
  z-index: 2;
}

// New css

$color_11: #63d3a6;
$color_21: #676767;
$color_31: #bebebe;
$background-color_11: #fff;

.up-icn {
  font-size: 120px !important;
  width: 60%;
  padding: 10px;
}

.profile {
  input[type="file"] {
    display: none;
  }

  .custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
  }

  .img-wrap {
    position: relative;
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 100px;
  }

  .img-upload {
    &:before {
      font-size: 90px;
      position: absolute;
      padding-top: 80px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: $color_11;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      opacity: 0;
      transition: 0.5s ease;
    }
  }

  img {
    width: auto;
    height: 100%;
  }

  input {
    border-radius: 15px;
    border: 1px solid #b7b7b7;
    padding: 5px 5px 5px 10px;
    font-size: 18px;
    transition: 0.2s;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $color_31;
    }
  }
}

.modal-header {
  border-bottom: none !important;
}

.searchBox {
  position: relative !important;

  .icon_down_dir {
    position: absolute;
  }
}
.modal-dialog {
  max-width: 45% !important;
  margin: 1.75rem auto !important;
}

@media (max-width: 521px) {
  .identy {
    flex-direction: column;
  }
  .modal-dialog {
    padding: 0px 8px;
    max-width: 100% !important;
    margin: 1.75rem auto !important;
  }
}
@media (min-width: 521px) and (max-width: 768px) {
  .modal-dialog {
    max-width: 70% !important;
    margin: 1.75rem auto !important;
  }
}
@media (min-width: 768px) and (max-width: 1280px) {
  .modal-dialog {
    max-width: 50% !important;
    margin: 1.75rem auto !important;
  }
}

@media (min-width: 521px) {
}
.modal-body.pop::-webkit-scrollbar {
  display: none;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden !important;
}

.valid-prof {
  color: red;
  text-align: left;
  font-size: 12px;
  margin: 0 0 8px 0;
}

.modal-dialog .top-title h2 {
  font-size: 32px;
  margin: 1rem;
}
.modal-dialog .modal-content-pop h6 {
  font-size: 22px;
  margin: 0;
}

.modal-dialog .coun-detail label {
  font-size: 18px;
  margin-bottom: 5px;
  line-height: 1.1;
}
.modal-dialog .coun-detail h5 {
  font-size: 16px;
}
