$color_1: #2a578d;
$transparent_2: #0000;

@keyframes load {
  20% {
    background-position: top;
  }
  40% {
    background-position: bottom;
  }
  60% {
    background-position: center;
  }
}
.loader {
  width: 14px;
  margin: 10px 0;
  aspect-ratio: 0.5;
  display: grid;
  background: radial-gradient($color_1 68%, $transparent_2 72%) center/100% 50%
    no-repeat;
  animation: load 1.2s infinite linear calc(var(--_s, 0) * 0.4s);
  transform: translate(calc(var(--_s, 0) * 150%));
  &:before {
    width: 14px;
    aspect-ratio: 0.5;
    display: grid;
    background: radial-gradient($color_1 68%, $transparent_2 72%) center/100%
      50% no-repeat;
    animation: load 1.2s infinite circle calc(var(--_s, 0) * 0.4s);
    transform: translate(calc(var(--_s, 0) * 150%));
    content: "";
    grid-area: 1/1;
    --_s: -1;
  }
  &:after {
    width: 14px;
    aspect-ratio: 0.5;
    display: grid;
    background: radial-gradient($color_1 68%, $transparent_2 72%) center/100%
      50% no-repeat;
    animation: load 1.2s infinite linear calc(var(--_s, 0) * 0.4s);
    transform: translate(calc(var(--_s, 0) * 150%));
    content: "";
    grid-area: 1/1;
    --_s: 1;
  }
}
.load {
  margin: 0;
  // min-height: 100vh;
  display: grid;
  place-content: center;
}
