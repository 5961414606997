.tech-resignation {
    background-color: #d1d9e6 !important;
    padding: 20px;
    border-radius: 8px !important;
    margin: 3rem 0rem;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.profile-box  {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/214624/jordan-arnold-Anosw0HcGRk-unsplash.jpg);
    background-size: cover;
    background-position: center;
    height: 250px;
    width: 250px !important;
    margin-left: 1rem;
    margin-right: 3rem;
    .profile {
        backdrop-filter: blur(4px);
        img{
            height: auto;
        }
        .img-wrap {
            width: 150px;
            height: 150px;
        }
    }
}
.dropdown button{
    margin: 0.5rem;
}
.dropdown button:first-child{
    margin-left: 0rem;
}

.studentprofile-pop .modal-body-div input, select {
    padding: 10px;
    border: none;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    color: #2a578d;
}
label.user-name {
    font-size: 24px;
    margin-bottom: 1rem;
    font-weight: 500;
}
.studentprofile-pop textarea {
    height: auto;
    resize: none;
    border: none;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.studentprofile-pop .modal-body-div input:focus-visible {
    outline-color: #ffff;
}
.studentprofile-pop textarea:focus-visible{
  outline: none;
}

.drop-costmize{
    padding: 4px 8px;
    width: 250px;
    font-size: 18px;
    font-weight: 300;
    
    label {
        display: flex;
        align-items: center;
        border-bottom: 1px solid lightgray;
         padding-bottom: 8px;
        input {
            width: 16px;
            height: 16px;
        }
        
        span {
            padding-left: 0.5rem;
        }
    }
}

.drop-btn-costmize{
    background-color: #2b2d42 !important;
    color: #fff !important;
    border-color: #2b2d42 !important;
}
button.drop-btn-costmize:focus {
    outline: none !important;
   box-shadow: none !important;
}
.drop-scroll-costmaize{
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(0px, 48.1818px, 0px);
    max-height: 150px;
    overflow: scroll;
    overflow-x: hidden;
}
.drop-scroll-costmaize.dropdown-menu.show {
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey; 
        border-radius: 10px;
      }
       
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #2a578d; 
        border-radius: 10px;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #2a578d; 
      }
}
.studentprofile-pop .submit-btn .btn {
    width: 30%;
}   
@media (min-width: 768px) and (max-width:991px) {
    .img-content{
        justify-content: center;
    }
    .profile-box {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .modal-body-div{
        text-align: center;
    }
}
@media (min-width: 320px) and (max-width:767px) {
    .studentprofile-pop .submit-btn .btn {
        width: 50%;
    }
    .img-content{
        justify-content: center;
    }
    .profile-box {
        margin-left: 0rem;
        margin-right: 0rem;
    }
    .modal-body-div{
        text-align: center;
    }
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em !important;
    vertical-align: middle !important;
    content: "";
    border-top: .4em solid !important;
    border-right: .4em solid transparent !important;
    border-bottom: 0;
    border-left: .4em solid transparent !important;
}

form.session-dtl-pop-fnt p {
    color: #3d405b;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0rem;
    margin-top: 0.6rem;
    min-height: 0;
    text-transform: uppercase;
    font-family: Montserrat,sans-serif!important;
}

.select-categry-sarch-bar{
    input{
        border-radius: 6px;
        border: 1px solid lightgrey;
        margin-left: 8px;
        outline: unset;
    }
}

.category-item label {
    padding: 6px;
    background-color: white;
    margin: 4px 4px;
    border-radius: 4px;
    color: #3d405b;
    font-size: 16px;
    font-family: Montserrat,sans-serif!important;
}