$color_1: #fff;
$color_2: #3a4f68;
$background-color_1: #3a4f68;
$background-color_2: #fff;

.cover-section {
  position: relative;
  padding: 0;
}
.inn-txt.container h3 {
  font-size: 3rem;
}
.inn-txt.container h2 {
  font-size: 3rem;
  margin-top: 2rem;
}
.inn-txt.container .search-box {
  margin-top: 2rem;
}
.inn-txt.container {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .std-hm-btn {
    margin-bottom: 1rem;
  }
  .inn-txt.container h2 {
    font-size: 1.8rem;
    margin-top: 1rem;
  }
  .inn-txt.container h3 {
    font-size: 2rem;
  }

  .cover-section .inn-txt .std-hm-btn {
    margin-top: 2rem;
    // position: relative;
    top: 150px !important;
    padding: 10px;
    border-radius: 8px;
    left: 0 !important;
    background-color: #2b2d42;
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    width: 100%;
  }
}
@media (min-width: 991px) and (max-width: 1420px) {
  .cover-content {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(#2a578d99, $color_2);
    position: absolute;
    bottom: 0;
    color: $color_1;
    padding: 20px;

    .inn-txt {
      // position: absolute;
      // bottom: 10%;
      // left: 7%;

      .std-hm-btn {
        padding: 5px 15px;
        border-radius: 100px;
        border: 0;
        background-color: $color_1;
        color: $color_2;
        font-weight: 600;
      }
    }
  }
}

.cover-section {
  background-image: url("../../../images/MicrosoftTeams-image\ \(1\).png");
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position-y: top;
  background-position-x: center;
}

// btn
.cover-section .inn-txt .std-hm-btn {
  margin-top: 1rem;
  // position: relative;
  top: 100px;
  padding: 10px;
  border-radius: 8px;
  left: 4px;
  background-color: #2b2d42;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
}
.cover-section .inn-txt .std-hm-btn:hover {
  background-color: #2b2d4288;
}
.cover-content {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#2a578d99, $color_2);
  position: absolute;
  bottom: 0;
  color: $color_1;
  padding: 20px;
}
