.card {
  border: none !important;
}
@media (min-width: 320px) {
  .sec-wid {
    max-width: 24rem;
    overflow: scroll;
  }
  .dis-cont {
    display: flex !important;
    flex-wrap: nowrap !important;
    overflow-x: scroll;
  }
  /* width */
  .dis-cont::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  .dis-cont::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  .dis-cont::-webkit-scrollbar-thumb {
    background: #2a578d;

    border-radius: 5px;
  }

  .card-title {
    margin: 0rem;
    font-weight: 600;
    min-height: 42px;
    p {
      margin-bottom: 0;
      font-size: 13px;
    }
  }
  .card-text {
    margin-bottom: 0;
    p {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .rating {
    display: block;
    margin-bottom: 8px;
    img {
      width: 10px;
      margin: 0 2px;
    }
  }
  .rating-pop {
    display: block;
    margin-bottom:8px;
    img {
      width: 12px;
      margin: 0 2px;
    }
    .star-svg {
      width: 16px;
      height: 16px;
  }
  }
  .profile-img {
    // height: 5rem;
    width: 4rem;
    align-self: center;
  }
  // .couns-car {
  //   height: fit-content;
  //   width: fit-content;
  // }
}

// @media (max-width: 520px) {
//   .sec-wid {
//     max-width: 24rem;
//     overflow: scroll;
//   }
//   .dis-cont {
//     display: flex !important;
//     flex-wrap: nowrap !important;
//   }
//   .card-title {
//     margin: 0rem;
//     font-weight: 600;
//     min-height: 42px;
//     p {
//       margin-bottom: 0;
//       font-size: 13px;
//     }
//   }
//   .card-text {
//     margin-bottom: 0;
//     p {
//       margin-bottom: 0;
//       font-size: 12px;
//       font-weight: 500;
//     }
//   }
//   .rating {
//     display: block;
//     margin-bottom: 15px;
//     img {
//       width: 10px;
//       margin: 0 2px;
//     }
//   }
//   .rating-pop {
//     display: block;
//     margin-bottom: 15px;
//     img {
//       width: 12px;
//       margin: 0 2px;
//     }
//   }
// }
// @media (max-width: 375px) {
//   .sec-wid {
//     max-width: 375px;
//     overflow: scroll;
//   }
//   .dis-cont {
//     display: flex;
//     flex-wrap: nowrap;
//   }
//   .card-title {
//     margin: 0rem;
//     font-weight: 600;
//     min-height: 36px;
//     p {
//       margin-bottom: 0;
//       font-size: 12px;
//     }
//   }
//   .card-text {
//     margin-bottom: 0;
//     p {
//       margin-bottom: 0;
//       font-size: 12px;
//       font-weight: 500;
//     }
//   }
//   .rating {
//     display: block;
//     margin-bottom: 15px;
//     img {
//       width: 8px;
//       margin: 0 2px;
//     }
//   }
//   .rating-pop {
//     display: block;
//     margin-bottom: 15px;
//     img {
//       width: 10px;
//       margin: 0 2px;
//     }
//   }
// }
// @media (max-width: 320px) {
//   .sec-wid {
//     max-width: 320px;
//     overflow: scroll;
//   }
//   .dis-cont {
//     display: flex;
//     flex-wrap: nowrap;
//   }
//   .card-title {
//     margin: 0rem;
//     font-weight: 600;
//     min-height: 30px;
//     p {
//       margin-bottom: 0;
//       font-size: 10px;
//     }
//   }
//   .card-text {
//     margin-bottom: 0;
//     p {
//       margin-bottom: 0;
//       font-size: 10px;
//       font-weight: 500;
//     }
//   }
//   .rating {
//     display: block;
//     margin-bottom: 15px;
//     img {
//       width: 6px;
//       margin: 0 2px;
//     }
//   }
//   .rating-pop {
//     display: block;
//     margin-bottom: 15px;
//     img {
//       width: 8px;
//       margin: 0 2px;
//     }
//   }
// }
.skills .card {
  background-color: #2a578d6e !important;
  padding: 1rem;
  height: 160px;
  width: 160px;
}
.card-title p {
  margin-bottom: 0;
  font-size: 22px;
}
.card-text p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
}
span.style-module_emptyIcons__Bg-FZ.empty-icons {
  color: #d5d5d5 !important;
}
@media (min-width: 800px) and (max-width: 1199px) {
  .sec-wid {
    max-width: 60rem;
    overflow: scroll;
  }
}
@media (min-width: 1200px) and (max-width: 1920px) {
  .sec-wid {
    max-width: 80rem;
    overflow: scroll;
  }
  .profile-img {
    // height: 5rem;
    width: 4rem;
    align-self: center;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .top-title h2 {
    font-size: 28px !important;
  }
  .card-info-ditels {
    white-space: normal !important;
  }
  .top-counsellour-info-box {
    width: 150px !important;
    padding-left: 0.3rem !important;
    height: 180px !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .card-info-ditels {
    padding-left: 0.2rem !important;
    text-align: center;
    margin-top: 0.5rem;
    width: auto !important;
  }
}
@media (min-width: 320px) and (max-width: 420px) {
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 112vw;
    height: 112vh;
    background-color: #000;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .content {
    position: relative;
    width: 79% !important;
  }

  .card-info-ditels {
    white-space: normal !important;
  }
  .top-counsellour-info-box {
    width: 150px !important;
    padding-left: 0.3rem !important;
    height: 180px !important;
    display: flex !important;
    flex-direction: column;
    align-items: center;
  }
  .card-info-ditels {
    padding-left: 0.2rem !important;
    text-align: center;
    margin-top: 0.5rem;
  }
  .heading {
    margin-bottom: 5%;
  }
  .sec-wid {
    max-width: 100%;
  }
  .card {
    height: 175px;
  }
}

// .top-title h2 {
//   font-size: 22px;
// }
// .top-title h2::after {
//   content: "";
//   width: 100px;
//   height: 3px;
//   background: #2a578d;
//   bottom: -10px;
//   left: -10px;
// }
// .heading {
//   margin-bottom: 5%;
// }

.content .content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 160px;
  left: 0px;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  z-index: 99;
}
.content:hover .content-overlay {
  opacity: 1;
}

.fadeIn-bottom {
  top: 80%;
}
.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 80%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.content {
  position: relative;
  width: 60%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}
.content:hover .content-details {
  opacity: 1;
}
.content:hover .content-details {
  top: 25%;
  left: 50%;
  opacity: 1;
}

.content .text-center {
  padding: 0;
}
.content:hover .content-details {
  z-index: 999;
}
// .InfiniteCarouselFrame {
//   height: 300px;
// }

.skills-card-title p {
  color: #ffffff;
}

.content .text-center span {
  color: white;
  text-transform: uppercase;
}
.skills-card-title p {
  justify-content: unset;
  margin-left: 0px;
}
.top-counsellour-info-box {
  width: 230px;
  height: 130px;
  display: flex;
  background-color: #ffffff;
  padding: 6px;
  border-radius: 10px;
  padding-left: 1rem;
  align-items: center;
}
.card-info-ditels {
  width: 150px !important ;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 1rem;
  line-height: 1.2;
}
.card-info-ditels p {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}
.card-info-ditels span {
  font-size: 1rem;
  color: #ada0a0;
}
.card-info-ditels svg {
  width: 18px;
  height: 18px;
}

.InfiniteCarouselArrow {
  background-color: #ced2f7;
  width: 40px;
  height: 40px;
  padding: 6px;
  border-radius: 50px;
}
.InfiniteCarouselArrowIcon {
  display: inline-block;
  padding: 4px;
  border: solid #515151;
  border-width: 0 3px 3px 0;
}

.InfiniteCarousel button:focus {
  outline: unset;
  outline: unset;
}

.our-top-counsellor-flex {
  display: flex;
  justify-content: center;
}
section.top-counsellor {
  background-color: #3a4f68;
  padding: 1.5rem;
}
// .InfiniteCarouselArrowIcon {
//   padding: 6px !important;
//   border: solid #c4c2c2 !important;

// }

.card-info-ditels {
  white-space: normal !important;
}
@media (min-width: 991px) and (max-width: 1200px) {
  .content {
    position: relative;
    width: 79%;
  }
}
@media (min-width: 320px) and (max-width: 567px) {
  .top-title h2::after {
    content: "";
    width: 70px;
  }
}
.main-div-popular-skills {
  padding: 0.5rem;
}
.popular_Skills_card_div {
  width: 240px;
  display: flex;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  height: 60px;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  p {
    margin: 0;
    color: #3d405b;
    font-size: 1rem;
    font-weight: 600;
    min-height: 0;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif !important;
  }
}

// start new
.counsellor-name-size{
  font-size: 16px;
  font-weight: 500;
}
.experience-fs{
  h5{
    font-size: 16px;
    font-weight: 500;
  }
}
.Sessions-fs{
  p{
    font-size: 16px;
    font-weight: 500;
  }
}
.skills-name{
  span{
    font-size: 16px;
    font-weight: 500;
  }
}

