.acor-abut {
    background-color: rgb(58 79 104) !important;
    min-height: 60px;
    border-radius: 15px 15px 0px 0px !important;
    margin: 20px 0;
}
.acor-abut .acor-summ {
    min-height: 60px !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    content: unset !important;
}
.acor-abut .acor-titl {
    color: #ffffff;
    display: contents;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
    min-height: 0;
    text-transform: uppercase;
    font-family: Montserrat,sans-serif!important;
}
.acor-abut .acor-ico {
    color: #ffffff;
}
.acor-abut .MuiAccordionDetails-root {
    padding: 1rem;
    color: #3a4f68;
    background-color: #fff;
}
.faq-counsellor-hedding {
    color: #2a578d;
    text-align: center;
    margin: 3rem 0rem  3rem 0rem;
}
.faq-counsellor-hedding h4 {
    position: relative;
    font-size: 32px;
}
.faq-counsellor-hedding h4:after {
    margin: 15px;
    content: "";
    width: 80px;
    height: 3px;
    background: #2a578d;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -10px;
    border-radius: 20px;
}
@media (min-width: 320px) and (max-width: 567px){
    .acor-abut .acor-titl {
        font-size: 14px;
    }
    .faq-counsellor-hedding h4 {
        font-size: 22px;
    }
}
.css-ahj2mt-MuiTypography-root {
    /* margin: 0; */
    /* font-family: "Roboto","Helvetica","Arial",sans-serif; */
    /* font-weight: 400; */
    /* font-size: 1rem; */
    /* line-height: 1.5; */
    letter-spacing: 0.00938em;
    color: #3d405b;
    font-size: 16px;
    font-family: Montserrat,sans-serif!important;
}