.sec-1 {
  display: block;
  text-align: center;
  img {
    width: 100px;
  }
  p {
    padding: 15px;
    margin-bottom: 0;
    text-align: start;
  }
}
.top-skills-card {
  .card-text {
    font-size: 1rem;
    color: #2a578d;
  }
  .top-skill-image-div {
    height: 250px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      border-radius: 1rem 1rem 0rem 0rem;
    }
  }
  .tittel {
    padding: 10px 15px;
    width: fit-content;
    border-radius: 50px;
    color: #ffffff;
    background-image: linear-gradient(315deg, #8d8d8d, #437fc7 74%);
    margin-bottom: 12px;
    font-size: 1rem;
  }
  button.session-btn {
    border: 0;
    padding: 4px 8px;
    font-size: 1rem;
    color: white;
    font-weight: 500;
    background-color: #f0ecfc;
    margin-top: 12px;
    background-image: linear-gradient(315deg, #65768b, #3d405b 74%);
    &:hover {
      background-color: #f0ecfc;
      background-image: linear-gradient(315deg, #65768b, #c4c5cf 74%);
    }
  }
}
// review css work
.feedbacks {
  .review-box {
    margin: 0.5rem 0rem;
    .review-info-div {
      display: flex;
      align-items: center;
      .image-div {
        width: 75px;
        height: 75px;
      }
      .review-info {
        justify-content: space-between;
        // margin-left: 1rem;
        .reviewer {
          font-size: 20px;
          margin-bottom: 0;
          font-weight: 500;
          color: #3d405b;
        }
        .ago-months {
          font-size: 16px;
          margin-bottom: 0;
          color: #2a578d;
          font-weight: 500;
        }
      }
    }
  }
  .description {
    font-size: 16px;
    color: #2a578d;
  }
}

@media (max-width: 820px) {
  .sec-1 {
    display: flex;
    text-align: center;
    margin-bottom: 20px;
    img {
      width: 70px;
      padding: 5px;
      align-self: center;
    }
    p {
      font-size: 14px;
      text-align: start;
      margin-bottom: 0;
      align-self: center;
      padding: 10px;
    }
  }
}

@media (min-width: 821px) {
  .sec-1 {
    display: flex;
    text-align: center;
    margin-bottom: 20px;
    img {
      width: 70px;
      padding: 5px;
      align-self: center;
    }
    p {
      font-size: 14px;
      text-align: start;
      margin-bottom: 0;
      align-self: center;
      padding: 10px;
    }
  }
}
@media (min-width: 768px) and (max-width: 2200px) {
  .guide {
    margin: 1% 1% 1% 1%;
  }
}

@media (max-width: 575px) {
  .review-info-div {
    display: block !important;
    .review-info {
      margin-top: 0.5rem !important;
    }
  }
}
