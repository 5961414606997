.aboutus-summery {
    margin: 2rem 0rem  3rem 0rem;
   h6 {
    text-align: start !important;
    font-size: 20px;
    line-height: 1.5;
    color: #3d405b !important;
    font-family: Montserrat,sans-serif!important;
    }
    h2{
        margin-bottom: 1.5rem;
        text-align: center;
        color: #3d405b;
        font-size: 2rem;
        font-weight: 600;
        min-height: 0;
        text-transform: uppercase;
        font-family: Montserrat,sans-serif!important;
    }
}
.Why-You-Need{
    margin: 2rem 0rem 2rem 0rem;
    padding: 3rem;
    background-color: #f8f9fa;
    z-index: 9;
    position: relative;
    h3{
    margin: 2rem 0rem 2rem 0rem;
    text-align: center;
    color: #3d405b;
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    font-family: Montserrat,sans-serif!important;
    }
    .hit-line-div{
        position: relative;
        ::before{
                top: 74px;
                position: absolute;
                left: 120px;
                display: block;
                width: 76%;
                height: 0;
                border-top: 1px dashed #b1b1b1;
                content: "";
                top: 56px;
                z-index: -1 !important;
        }
    }
    
    .card-img-title{
        text-align: center;
        .img-div-1{
            text-align: center;
            img{
                height: 60px;
                width: 60px;
                margin-bottom: 20px;
                background-color: #fff;
                &.feature-box-icon {
                    border-radius: 0px; 
                    margin-right: 0px; 
                }
                &.feature-box-icon.small {
                    padding: 15px;
                }
            }  
        }
       
        .title-div-1{
            h4{
             font-size: 24px;
            text-align: center;
            color: #3d405b;
            font-weight: 600; 
            font-family: Montserrat,sans-serif!important;
            }
        }
}
}
// .feature-box-icon:hover{
//     background-color: #3d405b !important;
// }
.our-vision{
   
    h2{
        color: #3d405b;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
    min-height: 0;
    text-transform: uppercase;
    font-family: Montserrat,sans-serif!important;
    }
    p{
        font-size: 18px;
    text-align: justify;
    color: #3d405b;
    font-family: Montserrat,sans-serif!important;
    }
    .vision-img{
        text-align: end;
        img{
            height: auto;
            max-width: 100%;
            width: 400px;
        }
    }

   

}
.our-mission{
    margin-bottom: 2rem;
    h2{
        color: #3d405b;
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 0.2rem;
        min-height: 0;
        text-transform: uppercase;
        font-family: Montserrat,sans-serif!important;
    }
    p{
        font-size: 18px;
    text-align: justify;
    color: #3d405b;
    font-family: Montserrat,sans-serif!important;
    }
    .mission-img{
        text-align: start;
        img{
            height: auto;
            max-width: 100%;
            width: 400px;
        }
    }
}


@media (min-width: 991px) and (max-width: 1200px){
    .title-div-1 h4 {
        font-size: 24px !important;
    }
}
@media (min-width: 768px) and (max-width: 991px){

    .Why-You-Need .card-img-title .title-div-1 h4 {
        font-size: 22px;
    }
    .Why-You-Need .hit-line-div ::before {
        content: unset;
    }
    .title-div-1 h4 {
        margin-bottom: 2rem;
    }
}
@media (min-width: 320px) and (max-width: 767px){
    .Why-You-Need h3 {
        font-size: 20px;
    }
    .aboutus-summery {
    margin: 2rem 0rem  3rem 0rem;
   h6 {
        font-size: 20px;
        line-height: 1.5;
        color: #717171;
    }
    h2{
        color:#3a4f68;
    }
}
    .aboutus-summery {
        margin: 2rem 0rem  3rem 0rem;
       h6 {
            font-size: 16px;
        }
    }
    .title-div-1 h4 {
        margin-bottom: 1rem !important;
        font-size: 18px !important;
    }
    .Why-You-Need .hit-line-div ::before {
        content: unset;
    }
    .our-vision .vision-img {
        text-align: center;
    }
    .our-mission .mission-img {
        text-align: center;
    }
    .our-mission {
        display: flex;
        flex-direction: column-reverse;
    }
   
}

@media screen and (max-width: 567px){
    .our-vision p {
        font-size: 16px;
        text-align: unset;
    }
    .our-vision h2 {
        font-size: 20px;
    }
    .our-mission h2 {
        font-size: 20px;
    }
    .our-mission p {
        font-size: 16px;
        text-align: unset;
    }
}

.our-vision .vision-img {
    text-align: end;
    align-self: center;
}
.our-mission .mission-img {
    text-align: start;
    align-self: center;
}