.reviews-sec-card {
  .img-titel-div2 {
    img {
      width: 70px;
      height: 70px;
    }
    .name-ditels {
      text-align: end;
    }
  }
}
.custom-hr {
  border: 0;
  border-top: 2px solid var(--primary-color); /* Adjust color and thickness as needed */
  margin: 10px 0; /* Adjust spacing as needed */
}

.reviews-section-teacher {
  background-color: #2a578d;
  padding: 2rem 0rem 2rem 0rem;
  margin-bottom: 2rem;
  h2 {
    font-size: 36px;
    color: #ffffff;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
  }
}
@media screen and (max-width: 567px) {
  .reviews-section-teacher h2 {
    font-size: 20px !important;
    color: #3a4f68;
  }
  .reviews-section-teacher P {
    font-size: 18px !important;
  }
}

.reviews-section-teacher {
  h2 {
    color: #ffffff;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
    min-height: 0;
    font-family: Montserrat, sans-serif !important;
  }
  P {
    color: #ffffff;
    font-size: 20px;
    font-family: Montserrat, sans-serif !important;
  }
}
