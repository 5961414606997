.primary-bg{
  background-color: #2a578d !important;
}
.primary-text{
  color: #2a578d !important;
}
.counsellor-dashboard {
  .couns-img {
    height: 180px;
    overflow: hidden;
    display: inline-block;
    width: 180px;
    margin: 0 auto;
  }

  .profileDetails {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;

    p {
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 500;
    }
    label {
      margin-top: 5px;
      color: #2a578d;
      font-size: 18px;
    }
    span {
      text-align: center;
      border: 2px solid #2a578d;
      border-radius: 18px;
      font-weight: 500;
      color: #2a578d;
      margin: 10px 10px 0 0;
    }
  }

  .balance-card {
    background-color: #2a578d;
    color: #fff;

    label {
      font-weight: 500;
      font-size: 18px;
      text-align: left;
    }
    span {
      text-align: right;
    }

    .balance {
      background-color: #ffd100;
      color: #2a578d;

      span {
        font-weight: 600;
      }
    }
  }
  .heading.faq-page-heading {
    font-size: 24px !important;
  }
  .card-counselling {
    display: flex;
    justify-content: flex-start;
  }
  .empty-counselling {
    padding: 30px;
  }
  .after-create-session {
    position: relative;
    border-bottom: 0.5px solid #2a578d;
    background-image: linear-gradient(to top, #fff 0%, #fff 21%, #2a578d 250%);
    padding: 20px;

    .ses-titl {
      border-bottom: 2px solid #2a578d;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    h6 {
      color: #2a578d;
      font-size: 22px;
      margin: 0;
      text-align: left;
    }

    .notifi {
      position: relative;

      svg {
        border: 2px solid #2a578d;
        border-radius: 50%;
        color: #2a578d;
        font-size: 24px;
        padding: 2px;
        height: 30px;
        width: 30px;
      }
      .notifi-num {
        position: absolute;
        background-color: red;
        font-size: 12px;
        padding: 0px 6px;
        border-radius: 50px;
        top: -3px;
        right: -5px;
        color: #fff;
      }
    }
    .card-detail-n {
      p {
        display: flex;
        width: 48%;
        margin: 1%;
        flex-direction: column;
        float: left;
        flex-wrap: wrap;
        align-content: center;
        text-align: center;
        align-items: center;
        border: 2px solid #2a578d;
        border-radius: 50px;
        color: #2a578d !important;
        font-size: 16px;
        text-align: left;
        font-weight: 500;

        span {
          font-weight: 500;
          font-size: 18px;
          color: #2a578d;
        }
      }
    }
    button.details-btn {
      background-color: #2a578d;
      border: none;
      padding: 4px 16px;
      border-radius: 4px;
      margin-top: 0.5rem;
      font-weight: 500;
      color: #fff;
    }
  }
  .det-button {
    text-align: center !important;

    .custom-btn {
      color: #ffffff;
      border-radius: 5px;
      padding: 0px 25px !important;
      font-family: "Lato", sans-serif;
      font-weight: 500;
      transition: all 0.3s ease;
      position: relative;
      display: inline-block;
      box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
        7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
      outline: none;
    }
    .btn-8 {
      background-color: #f0ecfc;
      background-image: linear-gradient(315deg, #65768b 0%, #3d405b 74%);
      line-height: 42px;
      padding: 0;
      border: none;

      &:hover {
        background: transparent;
        color: #3d405b;
      }
      &:before,
      &:after {
        position: absolute;
        content: "";
        right: 0;
        bottom: 0;
        background: #4b0e78;
        transition: all 0.3s ease;
      }
    }
    button {
      margin: 20px;
    }
  }

  .reviews {
    position: relative;
    color: black !important;

    .rating-desc {
      .col-md-3 {
        padding-right: 0px;

        .fa {
          margin-right: 5px;
          color: #ffbc0b;
        }
      }
    }

    .progress {
      display: flex;
      height: 1.5rem;
      overflow: hidden;
      font-size: 0.75rem;
      background-color: #e9ecef;
      border-radius: 0.25rem;

      .progress-bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        background-color: #ffbc0b;
        transition: width 0.6s ease;

        .sr-only {
          margin-left: 5px;
          overflow: visible;
          clip: auto;
        }

        .fa-sr-only,
        .fa-sr-only-focusable:not(:focus),
        .sr-only,
        .sr-only-focusable:not(:focus) {
          position: absolute;
          width: unset;
          height: unset;
          padding: 0;
          //margin: 0px;
          //overflow: hidden;
          //clip: rect(0,0,0,0);
          white-space: nowrap;
          border-width: 0;
        }
      }
    }

    .rating-num {
      margin-top: 0px;
      font-size: 60px;
      line-height: 1;
    }
  }
  .feedbacks {
    .linepart {
      background-color: #a19696;
      height: 2px;
    }

    .review-box {
      margin: 0.5rem 0rem;

      .review-info-div {
        display: flex;
        align-items: center;

        .review-info {
          justify-content: space-between;

          .reviewer {
            font-size: 20px;
            margin-bottom: 0;
            font-weight: 500;
            color: #3d405b;
          }
          .ago-months {
            font-size: 16px;
            margin-bottom: 0;
            color: #2a578d;
            font-weight: 500;
          }
        }
      }
    }

    .description {
      font-size: 16px;
      color: #2a578d;
    }
  }
}

@media (max-width: 1366px) and (min-width: 1025px) {
  .counsellor-dashboard {
    .after-create-session {
      .notifi {
        .notifi-num {
          top: -5px;
          right: -9px;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 821px) {
  .counsellor-dashboard {
    .after-create-session {
      .notifi {
        .notifi-num {
          top: -5px;
          right: -2px;
        }
      }
    }
  }
}

@media (max-width: 820px) and (min-width: 521px) {
  .counsellor-dashboard {
    .after-create-session {
      .notifi {
        .notifi-num {
          top: -5px;
          right: -16px;
        }
      }
    }
  }
}
@media (max-width: 520px) {
  .counsellor-dashboard {
    .after-create-session {
      .notifi {
        .notifi-num {
          top: -5px;
          right: 6px;
        }
      }
    }
    .empty-counselling {
      padding: 20px 0;

      .after-create-session {
        p {
          font-size: 14px;
        }
        h6 {
          font-size: 18px;
        }
        .card-detail-n {
          p {
            align-content: center;
            align-items: center;
            border: 2px solid #2a578d;
            border-radius: 50px;
            color: #2a578d !important;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            float: left;
            justify-content: space-between;
            margin: 1%;
            padding: 10px;
            text-align: center;
            width: 100%;
          }
        }
        .ses-dtl {
          p {
            font-size: 14px;
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
    .review-info-div {
      display: flex !important;
    }
  }
}
.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 375px) {
  .counsellor-dashboard {
    .after-create-session {
      .notifi {
        .notifi-num {
          top: -5px;
          right: 0px;
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .counsellor-dashboard {
    .after-create-session {
      .notifi {
        .notifi-num {
          top: -5px;
          right: -7px;
        }
      }
    }
  }
}
@media (max-width: 520px) {
  .counsellor-dashboard {
    .after-create-session {
      .notifi {
        .notifi-num {
          top: -5px;
          right: 6px;
        }
      }
    }
    .empty-counselling {
      padding: 20px 0;

      .after-create-session {
        p {
          font-size: 14px;
        }
        h6 {
          font-size: 18px;
        }
        .card-detail-n {
          p {
            align-content: center;
            align-items: center;
            border: 2px solid #2a578d;
            border-radius: 50px;
            color: #2a578d !important;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            float: left;
            justify-content: space-between;
            margin: 1%;
            padding: 10px;
            text-align: center;
            width: 100%;
          }
        }
        .ses-dtl {
          p {
            font-size: 14px;
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
    .review-info-div {
      display: flex !important;
    }
  }
}
@media (max-width: 375px) {
  .counsellor-dashboard {
    .after-create-session {
      .notifi {
        .notifi-num {
          top: -5px;
          right: 0px;
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .counsellor-dashboard {
    .after-create-session {
      .notifi {
        .notifi-num {
          top: -5px;
          right: -7px;
        }
      }
    }
  }
}

.conformation-session {
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;

    input,
    textarea {
      margin: 5px 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      border-radius: 4px;
      outline: unset;
      border: 1px solid #2a578d;
    }

    #result {
      padding: 4px 8px;
      border-radius: 8px;
      margin-right: 5px;
      margin-bottom: 5px;
      color: white;
      background-color: #2a578d;
      font-size: 16px;

      button {
        background: transparent;
        color: #fff;
        border-radius: 50px;
        padding: 0px 7px;
        margin-left: 10px;
        font-weight: 500;
        border: 1px solid #fff;
      }
    }
  }
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -3px;
  right: -21px;
  color: inherit;
}

.close {
  float: right;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
  text-decoration: none;
  cursor: pointer;
}
