.introduction {
  // position: sticky;
  // top: 4px;
  // z-index: 99;
  background-color: white;
}

.linepart {
  background-color: #a19696;
  height: 2px;
}

.fa {
  margin-right: 5px;
}

.rating .fa {
  font-size: 22px;
}
.try {
  color: #5bc0de;
}
.rating-num {
  margin-top: 0px;
  font-size: 60px;
}

.progress {
  margin-bottom: 5px;
}

.progress-bar {
  text-align: left;
}

.rating-desc .col-md-3 {
  padding-right: 0px;
}

.sr-only {
  margin-left: 5px;
  overflow: visible;
  clip: auto;
}

.progress-bar-success {
  background-color: #5cb85c;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-info {
  background-color: #5bc0de;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-warning {
  background-color: #f0ad4e;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.progress-bar-danger {
  background-color: #d9534f;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}
.counsellor-dashboard .couns-img {
  height: 180px;
  overflow: hidden;
  display: inline-block;
  width: 180px;
  margin: 0 auto;
}

.profileDetails {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;

  p {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
  }
  label {
    margin-top: 5px;
    color: #2a578d;
    font-size: 18px;
  }
  span {
    text-align: center;
    border: 2px solid #2a578d;
    border-radius: 18px;
    font-weight: 500;
    color: #2a578d;
    margin: 10px 10px 0 0;
  }
}

.ses-titl {
  border-bottom: 2px solid #2a578d;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.ses-dtl {
  p {
    font-weight: 500;
    font-size: 18px;

    span {
      font-weight: 500;
      font-size: 18px;
      color: #2a578d;
    }
  }
}

.notifi-icn svg {
  border: 2px solid #2a578d;
  border-radius: 50%;
  color: #2a578d;
  font-size: 24px;
  padding: 2px;
  height: 30px;
  width: 30px;
}

.notifi {
  position: relative;

  .notifi-num {
    position: absolute;
    background-color: red;
    font-size: 12px;
    padding: 0px 6px;
    border-radius: 50px;
    top: -3px;
    right: -5px;
    color: #fff;
  }
}

@media (max-width: 1366px) and (min-width: 1025px) {
  .notifi .notifi-num {
    position: absolute;
    background-color: red;
    font-size: 12px;
    padding: 0px 6px;
    border-radius: 50px;
    top: -5px;
    right: -9px;
    color: #fff;
  }
}

@media (max-width: 1024px) and (min-width: 821px) {
  .notifi .notifi-num {
    position: absolute;
    background-color: red;
    font-size: 12px;
    padding: 0px 6px;
    border-radius: 50px;
    top: -5px;
    right: -2px;
    color: #fff;
  }
}

@media (max-width: 820px) and (min-width: 521px) {
  .notifi .notifi-num {
    position: absolute;
    background-color: red;
    font-size: 12px;
    padding: 0px 6px;
    border-radius: 50px;
    top: -5px;
    right: -16px;
    color: #fff;
  }
}
@media (max-width: 520px) {
  .notifi .notifi-num {
    position: absolute;
    background-color: red;
    font-size: 12px;
    padding: 0px 6px;
    border-radius: 50px;
    top: -5px;
    right: 6px;
    color: #fff;
  }
}
@media (max-width: 375px) {
  .notifi .notifi-num {
    position: absolute;
    background-color: red;
    font-size: 12px;
    padding: 0px 6px;
    border-radius: 50px;
    top: -5px;
    right: 0px;
    color: #fff;
  }
}
@media (max-width: 320px) {
  .notifi .notifi-num {
    position: absolute;
    background-color: red;
    font-size: 12px;
    padding: 0px 6px;
    border-radius: 50px;
    top: -5px;
    right: -7px;
    color: #fff;
  }
}
