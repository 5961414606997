.transaction-card.shadow-sm {
  border-radius: 8px 8px 0px 0px;
  padding: 0.5rem;
  background-color: #9290c3;
}
.transaction-card-ditel {
  border-radius: 0px 0px 8px 8px;
  padding: 0.5rem;
  border-top: 1px dashed;
}
.identy-click-row {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.history-transaction {
  background-color: #fff;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1803921569);
}
.trans-slip {
  background-color: #ffffff;
  padding: 8px;
  border-radius: 8px;
  margin: 10px 0px;
  border-bottom: 0.5px solid #d1d1d1;
  background-image: linear-gradient(to top, #fff 0%, #fff 50%, #90dffe 250%);
}

.trans-slip b {
  color: #2b2d6c;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif !important;
  width: 100px;
}

.trans-slip p {
  margin-bottom: 0.5rem;
  color: #2b2d42;
  font-size: 14px;
  font-family: Montserrat, sans-serif !important;
}
.trans-slip .amount {
  font-size: 20px;
}
span.cr-amount {
  font-weight: 600;
  color: green;
}
span.dr-amount {
  font-weight: 900;
  color: red;
}
@media (max-width: 991px) {
  .trans-slip b {
    font-size: 14px;
    width: 80px;
  }
  .trans-slip .amount {
    font-size: 16px;
  }
}
