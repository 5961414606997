$color_1: #2a578d;
$color_2: #2a578d;

.profile-main-title {
  border-bottom: 2px solid #bdbcbc4d;
  margin-bottom: 15px;
  h2 {
    color: $color_1;
    font-size: 22px;
    font-weight: 500;
  }
}
.bord-div {
  border: 1px solid #2a578d;
  padding: 8px;
  border-radius: 5px;
  margin: 4px 0;
}

.input-field {
  width: 100%;
  margin: 5px 0px !important;
  border: 1px solid #2a578d;
  border-radius: 5px;
  padding: 5px;
  outline: none;
  color: #3d405b;
  font-size: 16px;
  font-family: Montserrat, sans-serif !important;
}
.profile-sub-btn {
  width: 50%;
  font-weight: 500;
  border: 1px solid #2a578d;
  border-radius: 5px;
  color: $color_2;
  padding: 2px;
  background: none;
}
@media (min-width: 768px) and (max-width: 2200px) {
  .update-profile {
    // margin: 3% 10% 4% 10%;
    // padding: 10% 10% 10% 10%;
    .profile-sub-btn {
      width: 30%;
    }
    .profile-form {
      padding: 2rem;
      border-radius: 1rem;
    }
  }
  // .profile-input {
  //   width: 67% !important;
  // }
  .profile-input .input-field {
    font-size: 18px;
  }
}

.blur-bg-profile {
  background-image: url("https://images.unsplash.com/photo-1631631480669-535cc43f2327?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  height: 100%;
  background-size: cover;
  position: relative;
  filter: blur(4px);
  width: 100%;
}
.profile-img-form {
  width: 4rem;
  align-self: center;
  position: absolute;
  top: 17%;
  left: 17%;
  width: 350px;
  height: 350px;
  border: 5px solid #ffffff;
  border-radius: 50%;
}
.profile-sub-btn {
  width: 50%;
  font-weight: 500;
  border: 1px solid #2a578d;
  border-radius: 5px;
  color: #ffffff;
  padding: 2px;
  background: none;
  position: absolute;
  bottom: 20%;
  left: 15%;
  width: 20% !important;
  background-color: #2a578d;
}
.profile-input .input-field,
.profile-input select,
.profile-input .bord-div {
  font-size: 20px;
  margin: 5px 0px;
  color: #2a578d;
  border: rgba(230, 230, 230, 0.6);
  padding: 5px;
  width: 60%;
  background-color: rgba(230, 230, 230, 0.6);
  border-radius: 0;
  border: 2px solid #fff;
}
.profile-input .bord-div {
  left: 20%;
}
.profile-input .input-field:focus {
  // border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: #fff;
}
.profile-input select:focus {
  // border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: #fff;
}
.profile-input input.input-field:focus-visible {
  outline: unset;
  border: 2px solid;
}

.profile-input select:focus-visible {
  outline: unset;
  border: 2px solid;
}
@media (min-width: 1200px) and (max-width: 1366px) {
  .profile-img-form {
    left: 10%;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .main-profile-updt-div {
    width: 80% !important;
  }
  .counselling-profile.profile-form {
    width: 70% !important;
  }
  .img-contanent-btn {
    position: absolute;
    top: 10%;
    left: 10% !important;
  }
  .profile-img-form {
    left: 7%;
  }
  .profile-input .input-field,
  .profile-input select,
  .profile-input .bord-div {
    width: 80%;
  }
  .profile-input .bord-div {
    left: 10%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-profile-updt-div {
    width: 100% !important;
  }
  .counselling-profile.profile-form {
    width: 100% !important;
  }
  .profile-img-form {
    left: 7%;
    width: 250px;
    height: 250px;
  }
  .profile-input .bord-div {
    left: 0;
  }
  .profile-input .input-field,
  .profile-input select,
  .profile-input .bord-div {
    width: 100%;
  }
  .profile-sub-btn {
    bottom: 28%;
    left: 16%;
  }
  .profile-img {
    width: 150px !important;
    height: 150px !important;
  }
  .img-contanent-btn {
    left: 9% !important;
  }
}
@media (min-width: 567px) and (max-width: 767px) {
  .main-profile-updt-div {
    width: 100% !important;
  }
  .counselling-profile.profile-form {
    width: 100% !important;
  }
  button.update-profile {
    margin-top: 1rem;
  }
  .update-image-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 12px;
  }
  // new change
  .img-contanent-btn {
    text-align: center;
    margin-bottom: 2rem;
  }
  .profile-img {
    width: 150px !important;
    height: 150px !important;
    border: 2px solid #3d405b;
  }
  .profile-sub-btn {
    position: unset !important;
  }
  .blur-bg-profile {
    display: none;
  }
  .img-contanent-btn {
    position: unset !important;
  }
  .main-profile-updt-div {
    background-color: #ffff;
    padding: 2rem 0rem;
    border-radius: 8px;
  }
  //
  .blur-bg-profile {
    position: relative;
  }
  .profile-img-form {
    position: relative;
    top: -65%;
    left: 15%;
  }
  .profile-sub-btn {
    bottom: 55%;
    left: 33%;
    width: 30% !important;
  }
  .profile-input.text-center.pt-5.pb-5 {
    text-align: center;
    margin-top: 10rem;
  }
  .profile-input .input-field,
  .profile-input select,
  .profile-input .bord-div {
    width: 80%;
  }
  .profile-input .bord-div {
    left: 10%;
  }
}
@media (min-width: 320px) and (max-width: 567px) {
  .update-image-box {
    margin-top: 1rem;
  }
  .main-profile-updt-div {
    padding: 8px !important;
    width: 100% !important;
  }
  .counselling-profile.profile-form {
    width: 100% !important;
  }
  button.update-profile {
    margin-top: 1rem;
  }
  .update-image-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    padding: 12px;
  }
  .img-contanent-btn {
    text-align: center;
    margin-bottom: 2rem;
  }
  .profile-img {
    width: 150px !important;
    height: 150px !important;
    border: 2px solid #3d405b;
  }
  .profile-sub-btn {
    position: unset !important;
  }
  .blur-bg-profile {
    display: none;
  }
  .img-contanent-btn {
    position: unset !important;
  }
  .main-profile-updt-div {
    background-color: #ffff;
    padding: 2rem 0rem;
    border-radius: 8px;
  }
  .profile-img-form {
    position: relative;
    top: 17%;
    width: 200px;
    height: 200px;
    top: -65%;
    left: 25%;
  }
  .profile-input.col-md-6.col-12.text-center.pt-5.pb-5 {
    margin-top: 5rem;
  }
  .profile-input .input-field,
  .profile-input select,
  .profile-input .bord-div {
    width: 100%;
  }
  .profile-input .bord-div {
    left: 0;
  }
  .profile-sub-btn {
    bottom: 67%;
    left: 31%;
    width: 40% !important;
  }
}
@media (min-width: 320px) and (max-width: 375px) {
  .profile-img-form {
    left: 18%;
  }
  .profile-sub-btn {
    bottom: 69%;
    left: 30%;
    width: 40% !important;
  }
}
@media (min-width: 320px) and (max-width: 350px) {
  .profile-img-form {
    left: 13%;
  }
  .profile-sub-btn {
    bottom: 69%;
    left: 30%;
    width: 40% !important;
  }
}

.profile-main-title h2 {
  color: #2a578d;
  font-size: 34px;
  font-weight: 500;
  margin: 2rem 0rem 3rem 0rem;
}

// new update change
.profile-img {
  width: 150px;
  height: 150px;
  align-self: center;
  border-radius: 50%;
  padding: 12px;
}
.img-contanent-btn {
  position: absolute;
  top: 45%;
  left: 21%;
}

.img-contanent-btn {
  position: absolute;
  top: 10%;
  left: 14%;
}

.profile-img-top-counsellor {
  width: 4rem;
  height: auto;
}

//
button.update-profile {
  height: max-content;
  background-color: #2a578d;
  padding: 4px;
  border-radius: 5px;
  color: #fff;
}
input.input-field,
textarea.input-field {
  padding: 10px;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  color: #3d405b;
  font-size: 16px;
  font-family: Montserrat, sans-serif !important;
}
.update-image-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.skill-add-div button:focus {
  outline: none !important;
}
.skill-add-div p {
  margin: 0;
}
.skill-add-div {
  display: flex;
  background-color: lightsteelblue;
  border-radius: 4px;
  padding: 8px;
  justify-content: space-between;
  margin: 4px 0px;
}
.skill-add-div button {
  color: #fff;
  background-color: unset;
  border: none;
  font-weight: 500;
  display: flex;
  /* position: relative; */
}
.profile-form select {
  outline: none;
}
.counselling-profile.profile-form {
  width: 50%;
}

.main-profile-updt-div {
  width: 70%;
  margin: 0 auto;
  border-radius: 8px;
  padding: 2rem;
}

.student-profile-div {
  display: flex;
  justify-content: center;
}

.card-info-ditels p {
  color: #3d405b;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  font-family: Montserrat, sans-serif !important;
}
.card-info-ditels span {
  color: #3d405b;
  font-size: 16px;
  font-family: Montserrat, sans-serif !important;
}

form.profile-form {
  display: flex;
  justify-content: center;
}

.studentprofile-pop .modal-body-div input,
select {
  padding: 10px;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  color: #3d405b;
  font-family: Montserrat, sans-serif !important;
}

input.user-name-counsellor {
  background-color: unset;
  color: #3d405b;
  font-size: 20px !important;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif !important;
}

.profile-and-ditels {
  display: flex;
  align-items: center;
}
.studentprofile-pop textarea {
  height: 40px;
}
.image-profile-main-div {
  display: flex;
  justify-content: center;
}
ul.list-style-none li {
  list-style: none;
  padding: 10px 0;
}
