$color_1: #2a578d;
$color_2: #545454;
$color_3: #ffffff;
.submit-bid {
  padding: 1rem;
  .session-heading {
    h6 {
      font-size: 2rem;
    }
  }
  .costmize-did-ditel {
    font-size: 20px;
    color: #2a578d;
    p {
      background-color: #ffffff;
      padding: 6px;
      border-radius: 4px;
      box-shadow: 0px 0px 4px 2px rgba(200, 200, 200, 0.3);
      margin-top: 0.5rem;
      color: #3d405b;
      font-size: 16px;
      font-family: Montserrat, sans-serif !important;
      word-break: break-all;
    }
  }
}

.empty-counselling {
  border-radius: 14px;
  margin: 2rem 10px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1803921569);
  h6 {
    margin: 0;
    text-align: left;
    font-size: 16px;
    color: $color_1;
  }
  p {
    font-size: 14px;
    text-align: left;
    color: $color_2;
    margin: 0;
  }
}

.bid-section {
  // border: 1px solid $color_1;
  border-radius: 5px;
  margin: 10px;
  padding: 15px;
  box-shadow: 0px 0px 4px 2px #0000002e;
  p {
    margin-top: 1rem;
    color: #3d405b;
    font-size: 1rem;
    font-weight: 600;
    min-height: 0;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif !important;
  }
  .bid-list {
    margin-top: 8px;
    padding: 5px;
    box-shadow: 0px 0px 4px 1px #00000029;
    display: flex;
  }
  .bid-item {
    padding: 6px;
    border-radius: 4px;
    margin: 5px;
    box-shadow: 0px 0px 4px 1px #00000029;
    list-style: none;
    width: 50%;
    padding-left: 10px;
    color: #3d405b;
    font-size: 16px;
    font-family: Montserrat, sans-serif !important;
  }
  .price-input {
    position: relative;
    margin-top: 5px;
  }
  .unit {
    position: absolute;
    display: block;
    left: 6px;
    top: 4px;
    z-index: 9;
    font-size: 18px;
  }
  .manual-price {
    border-radius: 5px;
    text-align: end;
    display: block;
    border: 1px solid $color_1;
    background: $color_3;
    padding: 0px 5px 0px 20px;
    width: 100px;
    height: 32px;
    font-size: 20px;
  }
  .bid-submit {
    margin-top: 15px;
    background: #2a578d;
    width: 180px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    border: none;
    padding: 5px;
  }
}
.bid-section label {
  font-size: 16px;
  color: $color_1;
  font-weight: 500;
  margin-top: 1rem;
}
.confirm-bid {
  text-align: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 768px) and (max-width: 2200px) {
  section.bid {
    width: 77%;
    margin: 0 auto;
  }
}

p.costmize-did-ditel span {
  color: #3d405b;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif !important;
}
