body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 726px) {
  body {
    /* width: 26rem; */
    margin: 0px auto !important;
    box-shadow: 1px 1px 8px 0px #cdcbcd;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* width */
body::-webkit-scrollbar {
  width: 0px;
}
#root.msg-noti {
  position: relative !important;
}
.width-20{
  width:10% !important;
}

.no-content-section h2, .no-content-section h5 {
  display: inline-block;
  position: relative;
  color: #2a578d;
}