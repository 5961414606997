.contact-ul img {
    width: 25px;
    height: 25px;
}
 .contact-bx {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: rgba(100,100,111,.2) 0 7px 29px 0;
}
.ps-0 {
    padding-left: 0!important;
}
.contact-ul {
    width: 180px;
    text-align: start;
    margin: 0 auto;
}
.contact-bx .fas {
    color: #3d405b;
    font-size: 40px;
    margin-bottom: 0.5rem;
}
.contact-main-bx .contact-bx ul li a {
    color: #3d405b;
    font-size: 20px;
    font-family: Montserrat,sans-serif!important;
    text-decoration: none;
}
.contact-main-bx  .alinment-contect-dtl{
        justify-content: space-around;
        padding-top: 8px;
        align-items: center;
}
.contact-bx p {
    color: #3d405b;
    font-size: 20px;
    font-family: Montserrat,sans-serif!important;
    margin-bottom: 0;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    font-size: 1.5rem !important; 
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff !important;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    width: 120px;
    height: 62px;
    justify-content: center;
    border-radius: 6px;
    box-shadow:rgb(255 255 255 / 21%) 0 30px 60px -12px inset, rgb(251 207 207 / 30%) 0 18px 36px -18px inset;
    background: #0d1132 !important;
}

.contact-input-box .form-control:focus {
    color: unset;
    background-color: #e9ecef;
    border-color: unset;
    outline: unset;
    box-shadow: unset;
}
.contact-input-box .form-control {
    font-size: 20px;
    border: unset;
    background: #f8f9fa;
}
.contact-main-bx h1 {
    text-align: center;
    margin-bottom: 2rem;
}
p.time-digitel {
    padding-left: 8px;
}
// btn
button.btn-submit-contact {
    width: 200px;
    height: 50px;
    font-size: 20px;
    color: #f8f9fa;
    font-weight: 600;
    min-height: 0;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif !important;
    border-radius: 6px;
    background-color: #0d1132;
    box-shadow: rgba(255, 255, 255, 0.21) 0 30px 60px -12px inset, rgba(251, 207, 207, 0.3) 0 18px 36px -18px inset;
}
.contact-btn-box {
    text-align: center;
    margin-top: 1rem;
}

@media  (min-width: 768px) and (max-width:991px){
    .input-group-text {
        font-size: 1.5rem !important;
        width: 100px !important;
        height: 50px !important;
    }
    .contact-main-bx .contact-bx ul li a {
        font-size: 18px;
    }
    .contact-bx p {
        font-size: 18px;
    }
}
@media  (min-width: 320px) and (max-width:767px){
    .contact-bx .fas {
        font-size: 32px;
    }
    .contact-main-bx .contact-bx ul li a {
        font-size: 16px;
    }
    .contact-ul img {
        width: 20px;
        height: 20px;
    }
    .contact-main-bx .alinment-contect-dtl {
        justify-content: space-evenly;
    }
    .contact-bx p {
      font-size: 1rem;
    }
    .input-group-text {
        font-size: 1.5rem !important;
        width: 80px !important;
        height: 50px !important;
    }
    .contact-input-box .form-control {
        font-size: 18px;
    }
}


