.navbar-logo {
  // position: absolute;
  // bottom: 16px;
  // left: 51px;
  /* color: #fff; */
  /* transform: translate(25%, 50%); */
  a {
    color: #fff;
    text-decoration: none;
  }
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  // margin-left: 10px;
  margin-bottom: 0;
  cursor: pointer;

  a {
    &:hover {
      color: #fff !important;
    }
  }
}
