.why-chooses {
  margin-top: 2rem;
  list-style: none;
  padding: 0;

  .why-chooses-img-tittel {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    img {
      width: 40px;
      height: 40px;
    }

    h6 {
      margin: 0;
      font-size: 26px;
      align-self: center;
    }
  }

  p {
    margin-left: 1rem;
  }
}

.why-choose-text p {
  margin: 0;
}

@media (min-width: 320px) and (max-width: 767px) {
  h4.way-choos-contened {
    font-size: 18px;
  }
  .why-chooses-img {
    width: 15%;
    margin-top: 0.5rem;
  }
  .why-choose-text {
    width: 85%;
  }
  .why-chooses .why-chooses-img-tittel {
    align-items: flex-start;
    h6 {
      font-size: 20px;
    }
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .why-chooses-img {
    width: 10%;
  }
  .why-choose-text {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .why-chooses-img {
    width: 6%;
  }
  .why-choose-text {
    width: 95%;
  }
}

h4.way-choos-contened {
  color: #3d405b;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.2rem;
  min-height: 0;
  font-family: Montserrat, sans-serif !important;
}

.why-choose-text {
  h6 {
    color: #3d405b;
    font-size: 1rem !important;
    font-weight: 600;
    margin-bottom: 0.2rem;
    min-height: 0;
    font-family: Montserrat, sans-serif !important;
  }
  p {
    font-size: 1rem !important;
    margin-bottom: 0.2rem;
    min-height: 0;
    font-family: Montserrat, sans-serif !important;
  }
}
.card-info-ditels span {
  color: #3d405b !important;
  font-size: 16px;
  font-family: Montserrat, sans-serif !important;
}
