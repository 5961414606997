.heading {
  color: #2a578d;
  word-break: break-all;
  padding-top: 0rem;
  padding-left: 0rem;
  font-weight: 500;
  font-size: 22px;
  width: fit-content;
  margin: 0.3rem 0rem !important;
  .sessionCount {
    font-size: 1.2rem;
    color: #545454;
    font-weight: 600;
    // margin-top: -0.4em;
  }
  .sessionHour {
    font-size: 1rem;
    color: #545454;
    font-weight: 400;
    // margin-top: -0.8em;
  }
}
.calender {
  text-align: end;
  padding: 0;
  align-self: center;
  // padding-top: 4%;
  // padding-left: 2%;
  font-size: 2.6em;
  color: #2a578d;
  svg {
    vertical-align: top;
  }
}

.counselD {
  font-size: 1.24em;
  line-height: 1.7em;
  margin-bottom: 2%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  p {
    word-break: break-all;
    margin: 0% 2% 0% 2%;
    width: -moz-fit-content;
    width: fit-content;
    padding-top: 2%;
    color: #3d405b;
    font-size: 18px;
    font-family: Montserrat,sans-serif!important;
  }
}

.sessionSchedule {
  width: 99%;
  border-radius: 10px !important;
  height: fit-content;
  font-size: 1.25em;
  background-color: #2a578d;
  color: #ffffff;
  border-radius: 5px;
  .sessionTime {
    border-radius: 6px;
    background-color: #ffffff;
    color: #2a578d;
    width: fit-content;
    height: fit-content;
    margin-top: 7%;
    margin-left: 4%;
  }
}
.counseller-dtl-card {
  color: #2a578d;
  border-color: #2a578d;
  border-radius: 4%;
  margin: 2%;

  .session {
    border: 1.5px solid;
    color: #2a578d;
    border-spacing: 10%;
    width: 80%;
    border-color: #000000;
    border-radius: 4%;
    text-align: center;
    margin: 11%;
  }
 button {
    background-color: #2a578d;
    color: #ffffff;
    padding: 10px;
    border-radius: 4px;
    border: none;
    margin-top: 1rem;
}
}
@media only screen and (min-width: 768px) {
  .col-1.calender {
    text-align: center;
  }
  .calender-content {
    display: flex;
  }
  .counseller-dtl-card .session {
    color: #4A4A4A;
    border-color: #fff;
    text-align: center;
    margin: 0px;
    border: 1.5px solid;
    border-spacing: 10%;
    border-radius: 8px;
    margin: 1rem;
    word-wrap: break-word;
    padding: 10px;
  }
  .counseller-dtl-card {
    border: none;
    border-radius: 40px;
    margin: 2%;
  }
}
@media only screen and (min-width: 991px) {
  .counseller-dtl-card .session {
    color: #4A4A4A;
    border-color: #fff;
    text-align: center;
    margin: 0px;
    border: 1.5px solid;
    border-spacing: 10%;
    border-radius: 8px;
    margin: 1rem;
    word-wrap: break-word;
    padding: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .counselling-diteal-open {
    font-size: 16px !important;
   
}
.heading .sessionCount {
  font-size: 14px !important;
}
  .consellor-card-dtl p {
    font-size: 16px;
}
  .counselling-diteal-open {
    display: flex;
    align-items: unset !important;
}
  .counselling-diteal-open .fa-solid.fa-star {
    padding-right: 0.5rem;
    padding-top: 6px;
}
  .counselD {
    font-size: 1em;
}
  .calender svg {
     vertical-align: middle; 
}
  .heading .sessionCount {
    font-size: 1rem;
}
  .heading {
    font-size: 18px;
    margin: 0rem;
}
  .counseller-dtl-card {
    border-radius: 10px;
    margin: 10px;
  }
  .counselD p {
    padding: 10px;
  }
  .counseller-dtl-card .session {
    border-radius: 10px;
    text-align: center !important;
    margin: 10px;
    border-color: #2a57a9;
  }
}
.session .btn-div {
  text-align: center;
  margin-bottom: 10px;
}
.shape {
  border-radius: 0;
}
.shape p {
  margin-bottom: 0rem;
}
.counselling-diteal-open {
  display: flex;
}

.counselling-diteal-open .fa-solid.fa-star {
  padding-right: 0.5rem;
}
.counselling-diteal-open {
  color: #575757;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0.2rem;
    min-height: 0;
    font-family: Montserrat,sans-serif!important;
}
.counselling-hover:hover .counselling-diteal-open {
  color: #2a578d;
}
.row.calender-content {
  margin: 0;
  background-color: #e7ecff;
}
.counseller-dtl-card .session {
  width: auto;
  color: #4A4A4A;
  border-color: #fff;
  text-align: center;
  margin: 0px;
  border-spacing: 10%;
  border-radius: 8px;
  margin: 1rem;
  word-wrap: break-word;
  padding: 10px;
  background-color: #fff;
  h2{
    font-size: 24px;
  }
}


// new 

.consellor-card-dtl {

  p{
    margin-bottom: 0rem;
  }
}
.consellor-card-dtl .session {
  justify-content: center;
  display: grid;
  justify-items: center;
}

.session-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  margin: 1rem;
  p{
    margin-bottom: 0.5rem;
  }
  button{
    background-color: #2a578d;
    color: #ffffff;
    padding: 10px;
    border-radius: 4px;
    border: none;
    margin-top: 1rem;
  }
}

.counselling-diteal-open .fa-solid.fa-star {
  padding-top: 6px;
}
.session{
  h2 {
    color: #3d405b;
    font-size: 16px;
    font-family: Montserrat,sans-serif!important;
    font-weight: 600;
  }
  p{
    color: #3d405b;
font-size: 16px;
font-family: Montserrat,sans-serif!important;
  }
} 