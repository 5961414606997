$color_1: #2a578d;
$color_2: #fff;

.meet-session-form {
  border: 1px solid #2a578d;
  border-radius: 5px;
  margin: 10px;
  box-shadow: 0px 0px 4px 2px #0000002e;
  .session-heading {
    h6 {
      font-size: 18px;
      color: $color_1;
      font-weight: 500;
    }
  }
  .form-list {
    label {
      font-size: 14px;
      color: $color_1;
      text-align: left;
    }
    input {
      padding: 5px;
      height: 40px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #5277a3;
    }
    textarea {
      padding: 5px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #5277a3;
    }
  }
  #session-sub-btn {
    background: #2a578d;
    border: none;
    border-radius: 15px;
    padding: 3px 15px;
    width: 35%;
    color: $color_2;
  }
}
@media (min-width: 991px) {
  .meet-session-form {
    width: 40%;
  }
  .event-form {
    text-align: -webkit-center;
  }
}
