.all-noti-box{
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 1rem;
    justify-content: space-between;
    display: flex;
    p{
        padding: 1rem;
    margin: 0;
    color: #3d405b;
    font-size: 16px;
    font-family: Montserrat, sans-serif !important;
    margin: 0px;
    }
    .costom-w{
        width: 75%;
    }
}

@media (max-width: 991px){
    .all-noti-box{
        display: block;
        p{
            padding: 8px;
        }
    }
}