$color_1: #ffffff;
$color_2: #fff;
$color_3: black;
$color_4: #437fc7;
$background-color_1: #2c4c71;
$background-color_2: #fff;

.sec-wid {
  &::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 521px) {
  .sec-wid {
    max-width: 425px;
    // overflow: scroll;
  }
  .skill-slider {
    .dis-cont {
      display: flex;
      flex-wrap: nowrap;
      .col-5 {
        padding: 3px;
      }
    }
    .card {
      background-color: $background-color_1;
      border-radius: 8px;
      .top-title {
        h3 {
          &::after {
            margin: 15px;
            content: "";
            width: 60px;
            height: 2px;
            background: $color_1;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -10px;
            border-radius: 20px;
            margin: 15px;
            content: "";
            width: 60px;
            height: 3px;
            background: $color_4;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -10px;
            border-radius: 20px;
            margin: 15px;
            content: "";
            width: 22px;
            height: 1px;
            background: $color_1;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -5px;
            border-radius: 20px;
          }
          color: $color_1;
          font-size: 14px;
        }
      }
      p {
        color: $color_2;
        margin: 0;
        font-size: 12px;
      }
      &:hover {
        // border: 2px solid #437FC74D;
        background-color: $background-color_2;
        box-shadow: 0px 0px 5px 0px #437fc74d;
        opacity: 0.8;
        transition: 0.5s;
        p {
          color: $color_3;
          font-weight: 650;
        }
        h3 {
          color: $color_4;
        }
        .top-title {
          h3 {
            &::after {
              background: $color_4;
            }
          }
        }
      }
    }
    .card-body {
      padding: 5px;
    }
    .top-title {
      h3 {
        font-size: 22px;
        display: inline-block;
        position: relative;
        color: $color_4;
      }
    }
  }
  .skill-slider.top-title {
    text-align: center;
  }
}
@media (max-width: 520px) {
  .sec-wid {
    max-width: 425px;
    overflow: scroll;
  }
  .skill-slider {
    .dis-cont {
      display: flex;
      flex-wrap: nowrap;
      .col-5 {
        padding: 3px;
      }
    }
    .card {
      background-color: $background-color_1;
      border-radius: 8px;
      .top-title {
        h3 {
          &::after {
            margin: 15px;
            content: "";
            width: 60px;
            height: 2px;
            background: $color_1;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -10px;
            border-radius: 20px;
            margin: 15px;
            content: "";
            width: 60px;
            height: 3px;
            background: $color_4;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -10px;
            border-radius: 20px;
            margin: 15px;
            content: "";
            width: 22px;
            height: 1px;
            background: $color_1;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -5px;
            border-radius: 20px;
          }
          color: $color_1;
          font-size: 14px;
        }
      }
      p {
        color: $color_2;
        margin: 0;
        font-size: 12px;
      }
      &:hover {
        // border: 2px solid #437FC74D;
        background-color: $background-color_2;
        box-shadow: 0px 0px 5px 0px #437fc74d;
        opacity: 0.8;
        transition: 0.5s;
        p {
          color: $color_3;
        }
        h3 {
          color: $color_4;
        }
        .top-title {
          h3 {
            &::after {
              background: $color_4;
            }
          }
        }
      }
    }
    .card-body {
      padding: 5px;
    }
    .top-title {
      h3 {
        font-size: 22px;
        display: inline-block;
        position: relative;
        color: $color_4;
      }
    }
  }
  .skill-slider.top-title {
    text-align: center;
  }
}
@media (max-width: 375px) {
  .sec-wid {
    max-width: 375px;
    overflow: scroll;
  }
  .skill-slider {
    .dis-cont {
      display: flex;
      flex-wrap: nowrap;
      .col-5 {
        padding: 3px;
      }
    }
    .card {
      background-color: $background-color_1;
      border-radius: 8px;
      .top-title {
        h3 {
          &::after {
            margin: 15px;
            content: "";
            width: 60px;
            height: 2px;
            background: $color_1;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -10px;
            border-radius: 20px;
            margin: 15px;
            content: "";
            width: 60px;
            height: 3px;
            background: $color_4;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -10px;
            border-radius: 20px;
            margin: 15px;
            content: "";
            width: 22px;
            height: 1px;
            background: $color_1;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -5px;
            border-radius: 20px;
          }
          color: $color_1;
          font-size: 12px;
        }
      }
      p {
        color: $color_2;
        margin: 0;
        font-size: 10px;
      }
      &:hover {
        background-color: $background-color_2;
        border: 2px solid #437fc74d;
        box-shadow: 0px 0px 5px 0px #437fc74d;
        opacity: 0.8;
        transition: 0.5s;
        p {
          color: $color_3;
        }
        h3 {
          color: $color_4;
        }
        .top-title {
          h3 {
            &::after {
              background: $color_4;
            }
          }
        }
      }
    }
    .card-body {
      padding: 5px;
    }
    .top-title {
      h3 {
        font-size: 22px;
        display: inline-block;
        position: relative;
        color: $color_4;
      }
    }
  }
  .skill-slider.top-title {
    text-align: center;
  }
}
@media (max-width: 320px) {
  .sec-wid {
    max-width: 320px;
    overflow: scroll;
  }
  .skill-slider {
    .dis-cont {
      display: flex;
      flex-wrap: nowrap;
      .col-5 {
        padding: 3px;
      }
    }
    .card {
      background-color: $background-color_1;
      border-radius: 8px;
      .top-title {
        h3 {
          &::after {
            margin: 15px;
            content: "";
            width: 60px;
            height: 2px;
            background: $color_1;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -10px;
            border-radius: 20px;
            margin: 15px;
            content: "";
            width: 60px;
            height: 3px;
            background: $color_4;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -10px;
            border-radius: 20px;
            margin: 15px;
            content: "";
            width: 22px;
            height: 1px;
            background: $color_1;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -5px;
            border-radius: 20px;
          }
          color: $color_1;
          font-size: 10px;
        }
      }
      p {
        color: $color_2;
        margin: 0;
        font-size: 8px;
      }
      &:hover {
        background-color: $background-color_2;
        border: 2px solid #437fc74d;
        box-shadow: 0px 0px 5px 0px #437fc74d;
        opacity: 0.8;
        transition: 0.5s;
        p {
          color: $color_3;
        }
        h3 {
          color: $color_4;
        }
        .top-title {
          h3 {
            &::after {
              background: $color_4;
            }
          }
        }
      }
    }
    .card-body {
      padding: 2px;
    }
    .top-title {
      h3 {
        font-size: 22px;
        display: inline-block;
        position: relative;
        color: $color_4;
      }
    }
  }
  .skill-slider.top-title {
    text-align: center;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .w-costumize {
    width: 55% !important;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .w-costumize {
    width: 40% !important;
  }
}

@media (min-width: 991px) {
  .w-costumize {
    width: 28% !important;
  }
}
